import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MatterService {
  private readonly mattersURL = environment.main_url + environment.getAllMatters


  constructor( private readonly http: HttpClient) { }

  getAllMatters(pageNumber:number, pageSize:number,payload:any):any{
    return this.http.post<any>(this.mattersURL+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
  }

  updateMatterDataById(id:number, payload:any){
    return this.http.put<any>(this.mattersURL+"/"+id,payload); 
   
  }

  addMatter(data:any){
    return this.http.post<any>(`${this.mattersURL}`, data).pipe(
      map((result) => {
        return result;
      }),
      catchError((err) => {
        return of([]);
      }),
    );
  }

}
