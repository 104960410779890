import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskCatalogueService {

  private readonly taskCatalogUrl = environment.main_url + environment.taskCatalog
  
  constructor(private readonly http: HttpClient) { }

  getCatList(pageNumber:any,pageSize:any,payload:any){
    return this.http.post<any>(this.taskCatalogUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)

  }

  saveCatalog(payload:any,id:any){
    if(id)
      return this.http.put<any>(this.taskCatalogUrl+"/"+id,payload);  
    return this.http.post<any>(this.taskCatalogUrl,payload);
  }
}
