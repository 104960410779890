import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PagetitleComponent } from './pagetitle/pagetitle.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { NotificationComponent } from './notification/notification.component';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { helperService } from '../utils/helper';
import { ConfirmationAlertComponent } from './confirmation-alert/confirmation-alert.component';
import { DialogModule } from 'primeng/dialog';



@NgModule({
  declarations: [
    PagetitleComponent,
    UserModalComponent,
    NotificationComponent,
    ConfirmationAlertComponent
  ],
  imports: [
    CommonModule,
    MessagesModule,
    MessageModule,
    MultiSelectModule,
    FormsModule,
    CheckboxModule,
    ReactiveFormsModule,
    DialogModule
  ],
  providers:[ MessageService,helperService],
  exports: [PagetitleComponent, UserModalComponent, NotificationComponent,ConfirmationAlertComponent]
})
export class SharedModule { }
