import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectsGridService {
  private readonly projectsUrl = environment.main_url + environment.projectList
  private readonly dashboardprojectsUrl = environment.main_url + environment.dashboardProjects
  private readonly projectTypesList = environment.main_url + environment.projectTypesList
  private readonly lpcUsersList = environment.main_url + environment.lpcUsersList
  private readonly mattersURL = environment.main_url + environment.getAllMatters
  private readonly allOrgsUrl = environment.main_url + environment.allOrgs


  constructor(private readonly http: HttpClient) { }

  getProjectsList(pageNumber:any,pageSize:any,dashboard:boolean,payload:any){
    if(dashboard){
    
    if(pageNumber!=null&&pageSize!=null)
      return this.http.post<any>(this.dashboardprojectsUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
      return this.http.post<any>(this.dashboardprojectsUrl+"/filter",payload)
    
    }
    else{
    
    if(pageNumber!=null&&pageSize!=null)
      return this.http.post<any>(this.projectsUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
      return this.http.post<any>(this.projectsUrl+"/filter",payload)
    
    }
}
  getAllProjectsList(){
    return this.http.get<any>(this.projectsUrl)
  }

  getAllRequestedUsers(orgId:any){
    return this.http.get<any>(this.allOrgsUrl+'/'+orgId+'/requestedUsers')
  }

  getAllPendingItems(taskId:any){
    return this.http.get<any>(this.projectsUrl+'/'+taskId+'/pendingItems')
  }


  getProjectUnassignedTasksList(projectID:any){
    return this.http.get<any>(this.projectsUrl+"/"+projectID+"/tasks")
  }
  getProjectTypesList(){
    return this.http.get<any>(`${this.projectTypesList}`)
  }
  getLPCUsersList(payload:any){
    return this.http.post<any>(this.lpcUsersList,payload)
  }

  saveProject(payload:any,id:any){
    if(id)
      return this.http.put<any>(this.projectsUrl+"/"+id,payload);  
    return this.http.post<any>(this.projectsUrl,payload);
  }

  
  getAllMatters(payload:any){
    return this.http.post<any>(this.mattersURL+"/filter",payload)
  }
  getAllOrganizations(){
    return this.http.post<any>(this.allOrgsUrl+"/filter",{})
  }
}
