import { Component, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, EventClickArg, EventApi, Calendar, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { helperService } from 'src/app/utils/helper';
import { TkAvailabilityService } from '../tk-availability/tk-availability/tk-availability.service';

@Component({
  selector: 'app-timekeeper-dashboard',
  templateUrl: './timekeeper-dashboard.component.html',
  styleUrls: ['./timekeeper-dashboard.component.scss'],
  providers: [DecimalPipe],
  encapsulation: ViewEncapsulation.None
})
export class TimekeeperDashboardComponent implements OnInit {
  @ViewChild("calendar", { static: false })
  calendarComponent!: FullCalendarComponent;
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  submitted = false;
  transactions: any;
  newEventDate: any;
  events: any = [];
  isLoading:Boolean=false;
  category!: any[];
  calendarEvents!: any[];
  date: any;
  start: any;
  end: any;
  formData!: FormGroup;
  formEditData!: FormGroup;
  editEvent: any;
  currentEvents: EventApi[] = [];
  activeId = 1;
  dateSelected: any;
  calendar!: Calendar;
  isCollapsed = [false, false, false, false, false, false, false, false];
  alerts = [
    { name: 'Past Due Tasks' },
    { name: ' Tasks Needing Review' },
    { name: 'Tasks approaching Limit' },
    { name: ' Tasks Needing Acceptance' },
    { name: ' Tasks Needing Estimates' }
  ]
  @ViewChild('content') content: any;
  @ViewChild('nav') nav?: ElementRef<NgbNav>;
  @ViewChild('modalShow') modalShow !: TemplateRef<any>;
  @ViewChild('editmodalShow') editmodalShow!: TemplateRef<any>;

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear,prev',
      center: 'title',
      right: 'today,next,nextYear'
    },
    initialView: "dayGridWeek",
    firstDay: 1,
    themeSystem: "bootstrap",
    events: this.events,
    eventContent: this.customEventContent.bind(this),
    datesSet: this.handleMonthChange.bind(this),
    weekends: false,
    editable: false,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: false,
    contentHeight: 'auto'
  };
  constructor(private availablityService: TkAvailabilityService, private modalService: NgbModal, private formBuilder: FormBuilder, private filterService: helperService) {

  }

  ngOnInit() {

    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Dashboard' },
      { label: 'Dashboard', active: true }
    ];

    // VAlidation
    this.formData = this.formBuilder.group({
      title: ['', [Validators.required]],
      category: ['', [Validators.required]],
    });

    //Edit Data Get
    this.formEditData = this.formBuilder.group({
      editTitle: ['', [Validators.required]],
      editCategory: [],
    });

  }
  async handleMonthChange(payload: any) {
    this.setCalendarOptions([]);
    let start = this.filterService.convertDateToFormattedStr(payload.view.currentStart)
    let end = this.filterService.convertDateToFormattedStr(payload.view.currentEnd)
    this.loadAvailabilityList(start, end);
  }
  loadAvailabilityList(start: any, end: any) {
    this.start = start ? start : this.start;
    this.end = end ? end : this.end;
    this.isLoading=true;
    this.availablityService.getAllAvailabilityList(start, end,null).subscribe(res => {
      let list: any = []
      res.data.forEach((element: any) => {
        if (element.away == "Yes") {
          list.push({ title: "Not Available", date: element.availableDate, allDay: true, color: '#c97c7c', order: 5 })
        } else {
          if (element.morningSlot > 0) {
            list.push({ title: "Morning", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.morningSlot, order: 1 });
          }
          if (element.afternoonSlot > 0) {
            list.push({ title: "AfterNoon", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.afternoonSlot, order: 2 });
          }
          if (element.eveningSlot > 0) {
            list.push({ title: "Evening", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.eveningSlot, order: 3 })
          }
          if (element.morningSlot > 0 || element.afternoonSlot > 0 || element.eveningSlot > 0)
            list.push({ title: "Total Availability", date: element.availableDate, color: '#5c9f5c', availableTimeValue: Number(element.morningSlot) + Number(element.afternoonSlot) + Number(element.eveningSlot), order: 4 });

        }
      });
      // console.log(list)
      this.events = list;
      this.setCalendarOptions(list);
    })
  }
  customEventContent(arg: any) {
    //add time
    let timeText = document.createElement('span')

    if (arg.event.extendedProps.availableTimeValue) {
      timeText.className = "fc-event-time";
      timeText.innerHTML = this.getTimeKey(arg.event.extendedProps.availableTimeValue);

    }
    //include additional info
    let additionalText = document.createElement('i')
    additionalText.className = "fc-event-title";
    additionalText.innerHTML = arg.event.title;

    let arrayOfDomNodes = [timeText, additionalText]
    return { domNodes: arrayOfDomNodes }
  }
  getTimeKey(time: any) {
    return this.filterService.convertTimeNumToStr(time);
  }


  onAvailabilityChange(event: any) {
    let year = this.date.getFullYear();
    let month = this.date.getMonth() + 1 <= 9 ? '0' + (this.date.getMonth() + 1) : (this.date.getMonth() + 1);
    let day = this.date.getDate() <= 9 ? '0' + this.date.getDate() : this.date.getDate();
    let finalDate = year + "-" + month + "-" + day;
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(finalDate);
  }
  setCalendarOptions(list: any) {
    this.calendarOptions.events = [];
    this.calendarOptions = {
      headerToolbar: {
        left: 'prevYear,prev',
        center: 'title',
        right: 'today,next,nextYear'
      },
      initialView: "dayGridWeek",
      firstDay: 1,
      themeSystem: "bootstrap",
      events: list,
      eventContent: this.customEventContent.bind(this),
      datesSet: this.handleMonthChange.bind(this),
      weekends: false,
      editable: false,
      selectable: true,
      selectMirror: false,
      dayMaxEvents: false,
      contentHeight: 'auto'
    };
    this.isLoading=false;

  }

  expandAll() {
    this.isCollapsed = this.isCollapsed.map(item => {
      item = false
      return item;
    });
  }

  collapseAll() {
    this.isCollapsed = this.isCollapsed.map(item => {
      item = true
      return item;
    });
  }


  goToTasks() {
    this.activeId = 2
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }

  handleEventClick(clickInfo: EventClickArg) {
    this.editEvent = clickInfo.event;
    this.formEditData = this.formBuilder.group({
      editTitle: clickInfo.event.title,
      editCategory: clickInfo.event.classNames[0],
    });
    this.modalService.open(this.editmodalShow, { centered: true });
  }

  ngAfterViewInit() {

  }

  /**
   * Fetches the data
   */


  /***
   * Subscribe Model open
   */

}
