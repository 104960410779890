
const taskTypes = ['Bankruptcy','Contract Management Services','Corporate Services','Director Services','Family Law','Finance Services','Fund Services','Immigration Services','Intellectual Property','International Services','KYC/AML','Labor & Employment','Legal Project Services','Legal Research','Litigation','Treasury Services','Trusts & Estates','Internal Support', 'Operations',
'Representation Services',
'Disbursements',
'LeapLaw'];
const descriptionType=['Free','Auto'];
const rateType=['Fixed Rate','Hourly'];
const practiceArea=['Bankruptcy','Contract Management Services','Corporate Services','Director Services','Family Law','Finance Services','Fund Services','Immigration Services','Intellectual Property','International Services','KYC/AML','Labor & Employment','Legal Project Services','Legal Research','Litigation','Treasury Services','Trusts & Estates'];


export { taskTypes,descriptionType,rateType,practiceArea};