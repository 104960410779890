import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { task } from 'src/app/utils/filterDropDownData';
import { helperService } from 'src/app/utils/helper';

@Component({
  selector: 'tasks-grid-view',
  templateUrl: './tasks-grid-view.component.html',
  styleUrls: ['./tasks-grid-view.component.scss']
})
export class TasksGridViewComponent implements OnInit {

  @Input()
  taskAssignDialog: boolean = false;
  
  viewOnly: boolean = true;

  @Input()
  selectedRow: any;

  @Input()
  userTkPracticeAreas: any[] = [];

  @Input()
  reviewstatuslist: any[] = [];

  @Input()
  stateCodeMapList: any[] = [];

  @Input()
  canadaStateList: any[] = [];

  defaultTime:Date=new Date();

  @Input()
  lpcUsersList: any[] = [];

  taskForm: any = [];

  showCountry: any = false;
  showStateCode: any = false;
  showQuantityState: any = false;

  constructor(
    private filterService: helperService,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.taskForm = new FormGroup({
      id: new FormControl(''),
      taskName: new FormControl(''),
      projectName: new FormControl(''),
      orgName: new FormControl(''),
      matterName: new FormControl(''),
      qbTaskId: new FormControl(''),
      description: new FormControl(''),
      descriptionTypeCode: new FormControl(''),
      timeEstimate: new FormControl('', [Validators.required]),
      actualTime: new FormControl(''),
      isEnforceMaximum: new FormControl(''),
      stateCode: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      isCompleted: new FormControl('', [Validators.required]),
      projTaskAssignmentStatus: new FormControl(''),
      isBillable: new FormControl('', [Validators.required]),
      consolidatedTimeRecords: new FormControl('', [Validators.required]),
      isRequireEstimate: new FormControl('', [Validators.required]),
      statusCode: new FormControl('', [Validators.required]),
      isApprovalRequired: new FormControl('', [Validators.required]),
      taskDueDate: new FormControl('', [Validators.required]),
      projectDueDate: new FormControl(''),
      openEnded: new FormControl('', [Validators.required]),
      quantityState: new FormControl([]),
      quantityNumber: new FormControl(''),
      maxTime: new FormControl(''),
      rateTypeCode: new FormControl(''),
      isStateRelated: new FormControl(''),
      stateDesc: new FormControl(''),
      // stateCode:new FormControl(''),
      countryRelated: new FormControl(''),
      createdOn: new FormControl(''),
      createdBy: new FormControl(''),
      dueDaySlot: new FormControl(''),
      tkRating: new FormControl(''),
      quantityType: new FormControl(''),
      holdTimeUntilComp: new FormControl('', [Validators.required]),
      approver: new FormControl('', [Validators.required]),
      approved: new FormControl(''),
      timeRecordsLoggedTime: new FormControl(''),
      includeToday: new FormControl(''),
      projTaskAssignmentTkId: new FormControl(''),
      projTaskAssignmentUsername: new FormControl(''),
      quantityRelated: new FormControl(''),
      progress: new FormControl(''),
      reviewStatus: new FormControl(''),
      practiceAreaCode: new FormControl(''),
      notes: new FormControl(''),
      driveLink: new FormControl('')
    });
    // this.selectedRow.taskDueDate = this.datePipe.transform(this.selectedRow.taskDueDate, "MM/dd/yyyy");
    if (this.selectedRow.quantityType == "State") {
      this.showQuantityState = true;
      this.selectedRow.quantityState = this.selectedRow.quantityState ? this.selectedRow.quantityState.map((obj: any) => obj.stateCode) : [];
    } else {
      this.showQuantityState = false;
    }
    if (this.selectedRow.isStateRelated != "Yes") {
      this.taskForm.controls['stateCode'].disable()
      this.showStateCode = false;
    }
    if (this.selectedRow.countryRelated != "Yes") {
      this.taskForm.controls['country'].disable()
      this.showCountry = false;
    }
    this.taskForm.patchValue(this.selectedRow);
    this.taskForm.controls['timeEstimate'].value = this.filterService.convertTimeNumToStr(this.selectedRow.timeEstimate);
    this.taskForm.controls['maxTime'].value = this.filterService.convertTimeNumToStr(this.selectedRow.maxTime);
    this.taskForm.disable();
    
  }

  
  get timeEstimate() {
    return this.taskForm.get('timeEstimate');
  }
  get actualTime() {
    return this.taskForm.get('actualTime');
  }
  get stateCode() {
    return this.taskForm.get('stateCode');
  }
  get country() {
    return this.taskForm.get('country');
  }
  get consolidatedTimeRecords() {
    return this.taskForm.get('consolidatedTimeRecords');
  }
  get statusCode() {
    return this.taskForm.get('statusCode');
  }
  get quantityState() {
    return this.taskForm.get('quantityState');
  }
  get quantityNumber() {
    return this.taskForm.get('quantityNumber');
  }
  get approver() {
    return this.taskForm.get('approver');
  }
  get openEnded() {
    return this.taskForm.get('openEnded');
  }
  get approved() {
    return this.taskForm.get('approved');
  }
  get holdTimeUntilComp() {
    return this.taskForm.get('holdTimeUntilComp');
  }
  get quantityRelated() {
    return this.taskForm.get('quantityRelated');
  }
  get quantityType() {
    return this.taskForm.get('quantityType');
  }
  get isApprovalRequired() {
    return this.taskForm.get('isApprovalRequired');
  }
  get taskDueDate() {
    return this.taskForm.get('taskDueDate');
  }
  get isRequireEstimate() {
    return this.taskForm.get('isRequireEstimate');
  }
  get isBillable() {
    return this.taskForm.get('isBillable');
  }
  get isCompleted() {
    return this.taskForm.get('isCompleted');
  }
  get practiceAreaCode() {
    return this.taskForm.get('practiceAreaCode');
  }
  get tkRating() {
    return this.taskForm.get('tkRating');
  }
}
