import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import { DatePipe } from '@angular/common';
import { TkAvailabilityService } from './tk-availability.service';
import { helperService } from 'src/app/utils/helper';
import { ProjectsGridService } from '../../projects-grid/projects-grid.service';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import { UsersGridService } from '../../users-grid/users-grid.service';


@Component({
  selector: 'app-tk-availability',
  templateUrl: './tk-availability.component.html',
  styleUrls: ['./tk-availability.component.scss']
})
export class TkAvailabilityComponent implements OnInit {

  dialogFlag: boolean = false;
  mrgTimeDate: any;
  aftTimeDate: any;
  evnTimeDate: any;
  mrgTimeInvalid: Boolean = false;
  aftTimeInvalid: Boolean = false;
  evnTimeInvalid: Boolean = false;
  maxMorningHours:any;
  maxAfternoonHours:any;
  maxEveningHours:any;
  defaultTime:any;
  timeInvalid: Boolean = false;
  notAvailable: Boolean = false;
  btnLoading: Boolean = false;
  isLoading: Boolean = false;
  defaultDate:Date=new Date()
  today: any = "";
  start: any = "";
  end: any = "";
  events: any = [
  ]
  selectedDates:any;
  noDateRange:Boolean=false
  addTimeDialogue:Boolean=false;
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear,prev',
      center: 'title',
      right: 'today,next,nextYear'
    },
    initialView: 'dayGridMonth',
    events: this.events,
    eventOrder: "order",
    eventClick:this.handleClick.bind(this),
    dateClick: this.handleClick.bind(this),
    datesSet: this.handleMonthChange.bind(this),
    contentHeight: "auto",
    // height:500,
    eventContent: this.customEventContent.bind(this),

  };
  title: string = "Add Time";
  selectedUserId:any="";
  loggedInUser:any;
  usersList:any[]=[];
  startDate:any;
  endDate:any;
  enableAddTime:boolean=false;
  isAdminUser:boolean=false;
  initialLoad:boolean=true;
  excludeSunday:boolean=true;
  excludeSaturday:boolean=true;

  constructor( 
    private userService : UsersGridService, private authService: AuthGuard,private projectService: ProjectsGridService,private datePipe: DatePipe, private availablityService: TkAvailabilityService, private filterService: helperService, private _cdr: ChangeDetectorRef) {
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
    this.maxMorningHours = new Date();
    this.maxMorningHours.setHours(12, 0, 0, 0);
    this.maxAfternoonHours = new Date();
    this.maxAfternoonHours.setHours(5, 0, 0, 0);
    this.maxEveningHours = new Date();
    this.maxEveningHours.setHours(7, 0, 0, 0);
  }

  ngOnInit(): void {
    this.isLoading=true;
    if(this.filterService.isAdminUser(this.authService.roles)||this.filterService.isLPCUser(this.authService.roles)){
      this.isAdminUser=true;
      this.projectService.getLPCUsersList({
        "roles": ["LPC","TK","Approver"],
        "userTypes": []
      }).subscribe(res => {
        this.usersList = res.data;
      })
    }
   setTimeout(() => {
    this.userService.getLoginUser().subscribe(res => {
      this.selectedUserId = res.rows.id;
      this.loadAvailabilityList(this.startDate, this.endDate,this.selectedUserId);
      this.loggedInUser=res.rows;
      if(this.loggedInUser.id==this.selectedUserId){
        this.enableAddTime=true;
      }else{
        this.enableAddTime=false;
      }
    })
   }, 100);
  }

  async handleMonthChange(payload: any) {
    this.setCalendarOptions([]);
    let start = this.filterService.convertDateToFormattedStr(payload.view.activeStart)
    let end = this.filterService.convertDateToFormattedStr(payload.view.activeEnd)
    this.startDate=start;
    this.endDate=end;
    if(!this.initialLoad)
      this.loadAvailabilityList(start, end,this.selectedUserId);
    this.initialLoad=false;
    
  }

  handleClick(arg: any) {
    if(this.isAdminUser && this.loggedInUser.id!=this.selectedUserId)
      return;
    let date=arg.date?arg.date:arg.start;
    let dateString=arg.date?arg.dateStr:arg.startStr;
    
    if(date.getDate() < new Date().getDate() && date.getMonth() <= new Date().getMonth() && date.getFullYear() <= new Date().getFullYear())//skip all previous days 
        return;
    if(date.getMonth() < new Date().getMonth() && date.getFullYear() <= new Date().getFullYear())//skip all previous months
      return;
    if(date.getFullYear() < new Date().getFullYear())//skip all previous years
      return;
    this.today = dateString;
    let mrgTime = this.events.filter((obj: any) => obj.date == this.today && obj.title == "Morning").map((obj: any) => obj.availableTimeValue)[0]
      ? this.events.filter((obj: any) => obj.date == this.today && obj.title == "Morning").map((obj: any) => obj.availableTimeValue)[0] : 0;
    this.mrgTimeDate=this.filterService.convertTimeToDate(mrgTime);
    let aftTime = this.events.filter((obj: any) => obj.date == this.today && obj.title == "AfterNoon").map((obj: any) => obj.availableTimeValue)[0]
      ? this.events.filter((obj: any) => obj.date == this.today && obj.title == "AfterNoon").map((obj: any) => obj.availableTimeValue)[0] : 0
    this.aftTimeDate=this.filterService.convertTimeToDate(aftTime);
    let evnTime = this.events.filter((obj: any) => obj.date == this.today && obj.title == "Evening").map((obj: any) => obj.availableTimeValue)[0]
      ? this.events.filter((obj: any) => obj.date == this.today && obj.title == "Evening").map((obj: any) => obj.availableTimeValue)[0] : 0;
    this.evnTimeDate=this.filterService.convertTimeToDate(evnTime);
    this.notAvailable = this.events.find((obj: any) => obj.date == this.today && obj.title == "Not Available") ? true : false;
    this.title = "Edit Availability : " + this.datePipe.transform(dateString, "MM/dd/yyyy")
    this.dialogFlag = true;
  }
  getDaysArray = (start:any, end:any) =>{
    for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
  };

  updateMultipleAvailable(){
    if(this.selectedDates && this.selectedDates.length){
      let dates=this.getDaysArray(this.selectedDates[0],this.selectedDates[1])
      if(this.excludeSaturday){
        dates=dates.filter((date:Date)=>!this.filterService.isSaturday(date))
      }
      if(this.excludeSunday){
        dates=dates.filter((date:Date)=>!this.filterService.isSunday(date))
      }
      let count=dates.length
      for(let date of dates){
        count--;
        if(this.updateAvailable(date,count)==false){
          break;
        }
      }
    }
    else{
      this.noDateRange=true;
    }    
  }

  updateAvailable(currentDate:any,count?:any):any {
    if(!currentDate){
      currentDate=this.today;
    }
    let mrgTime=this.filterService.convertDateToTime(this.mrgTimeDate);
    let aftTime=this.filterService.convertDateToTime(this.aftTimeDate);
    let evnTime=this.filterService.convertDateToTime(this.evnTimeDate);

    this.mrgTimeInvalid=mrgTime>12*60;
    this.aftTimeInvalid=aftTime>5*60;
    this.evnTimeInvalid=evnTime>7*60;

   
    this.btnLoading = true;
    if (this.events.length && this.events.filter((obj: any) => obj.date == currentDate).map((obj: any) => obj.date).includes(currentDate))
      this.events = this.events.filter((obj: any) => obj.date != currentDate);

    let newList = Object.assign([], this.events);
    let resultDate = this.datePipe.transform(currentDate, "MM/dd/yyyy");
    let payload = {
      morningSlot: 0,
      afternoonSlot: 0,
      eveningSlot: 0,
      availableDate: resultDate,
      away: "No",

    }

    if (this.notAvailable) {
      newList.push({ title: "Not Available", availableDate: resultDate, allDay: true, color: '#c97c7c', order: 1 });
      payload.away = "Yes";
    } else {
      if(this.mrgTimeInvalid||this.aftTimeInvalid||this.evnTimeInvalid){
        this.btnLoading = false;
        return false;
      }

      if (mrgTime + aftTime + evnTime > 12*60) {
        this.timeInvalid = true;
        this.btnLoading = false;
        return false;
      }
      if (mrgTime > 0) {
        newList.push({ title: "Morning", availableDate: resultDate, color: '#3d82b9', availableTimeValue: mrgTime, order: 1 });
        payload.morningSlot = mrgTime;
      }
      if (aftTime > 0) {
        newList.push({ title: "AfterNoon", availableDate: resultDate, color: '#3d82b9', availableTimeValue: aftTime, order: 2 });
        payload.afternoonSlot = aftTime;
      }
      if (evnTime > 0) {
        newList.push({ title: "Evening", availableDate: resultDate, color: '#3d82b9', availableTimeValue: evnTime, order: 3 });
        payload.eveningSlot = evnTime;
      }
      if (mrgTime > 0 || aftTime > 0 || evnTime > 0)
        newList.push({ title: "Total Availability", availableDate: resultDate, color: '#5c9f5c', availableTimeValue: Number(mrgTime) + Number(aftTime) + Number(evnTime), order: 4 });
    }

    this.availablityService.saveTask(payload).subscribe(res => {
      if(count<=0|| !count){
        this.loadAvailabilityList(this.start, this.end,this.selectedUserId);
        this.btnLoading = false;
        this.closeDialog()
      }
      
    })
  }
  customEventContent(arg: any) {
    //add time
    let timeText = document.createElement('div')

    if (arg.event.extendedProps.availableTimeValue) {
      timeText.className = "fc-event-time";
      timeText.innerHTML = this.filterService.convertTimeNumToStr(arg.event.extendedProps.availableTimeValue);

    }
    //include additional info
    let additionalText = document.createElement('i')
    additionalText.className = "fc-event-title";
    additionalText.innerHTML = arg.event.title;

    let arrayOfDomNodes = [timeText, additionalText]
    return { domNodes: arrayOfDomNodes }
  }
  
  closeDialog() {
    this.dialogFlag = false;
    this.timeInvalid = false;
    this.notAvailable = false;
    this.addTimeDialogue=false;
    this.excludeSaturday=true;
    this.excludeSunday=true;
    this.selectedDates=null;
    this.noDateRange=false;
    this.mrgTimeDate=null;
    this.aftTimeDate=null;
    this.evnTimeDate=null;
  }

  loadAvailabilityList(start: any, end: any,userId:any) {
    this.start = start ? start : this.start;
    this.end = end ? end : this.end;
    this.isLoading = true;
    this.availablityService.getAllAvailabilityList(start, end,userId).subscribe(res => {
      let list: any = []
      res.data.forEach((element: any) => {
        if (element.away == "Yes") {
          list.push({ title: "Not Available", date: element.availableDate, allDay: true, color: '#c97c7c', order: 5 })
        } else {
          if (element.morningSlot > 0) {
            list.push({ title: "Morning", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.morningSlot, order: 1 });
          }
          if (element.afternoonSlot > 0) {
            list.push({ title: "AfterNoon", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.afternoonSlot, order: 2 });
          }
          if (element.eveningSlot > 0) {
            list.push({ title: "Evening", date: element.availableDate, color: '#3d82b9', availableTimeValue: element.eveningSlot, order: 3 })
          }
          if (element.morningSlot > 0 || element.afternoonSlot > 0 || element.eveningSlot > 0)
            list.push({ title: "Total Availability", date: element.availableDate, color: '#5c9f5c', availableTimeValue: Number(element.morningSlot) + Number(element.afternoonSlot) + Number(element.eveningSlot), order: 4 });

        }
      });
      // console.log(list)
      this.events = list;
      this.setCalendarOptions(list);
      this.isLoading = false;
    })
  }

  addTime(){
    this.addTimeDialogue=true;
  }

  setCalendarOptions(list: any) {
    this.calendarOptions.events = [];
    const that=this;
    this.calendarOptions = {
      headerToolbar: {
        left: 'prevYear,prev',
        center: 'title',
        right: 'today,next,nextYear'
      },
      initialView: 'dayGridMonth',
      events: list,
      eventOrder: "order",
      contentHeight: "auto",
      eventClick: function(info) {
        console.log(info.event.start);
        that.handleClick(info.event);
      },
      dateClick: this.handleClick.bind(this),
      datesSet: this.handleMonthChange.bind(this),
      // height:500,
      eventContent: this.customEventContent.bind(this),
    };
    
  }
  onUserChange(){
    if(this.loggedInUser.id==this.selectedUserId){
      this.enableAddTime=true;
    }else{
      this.enableAddTime=false;
    }
    this.loadAvailabilityList(this.startDate, this.endDate,this.selectedUserId);
  }
}

