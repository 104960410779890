import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectsGridService } from '../projects-grid/projects-grid.service';
import { helperService } from 'src/app/utils/helper';
import { PayrollTimeReportService } from './payroll-time-report.service';
import { Table } from 'primeng/table';


@Component({
  selector: 'payroll-time-report',
  templateUrl: './payroll-time-report.component.html',
  styleUrls: ['./payroll-time-report.component.scss']
})
export class PayrollTimeReportComponent implements OnInit {

  reportNonSummaryList: any[]=[];
  reportSummaryList: any[]=[];
  selectedUsers:any[]=[];
  allUsers:any[]=[];
  allOrganisations:any[]=[];
  selectedOrgs:any[]=[];
  isSummaryOnly:Boolean=false;
  generateLoader:Boolean=false;
  showSummaryOnly:Boolean=false;
  selectedDates:any;
  submitted:Boolean=false;
  defaultDate:Date=new Date();
  exportColumns:any[]=[]
  nonSummaryCols:any[]=[]
  summaryCols:any[]=[]
  title:any="";
  allMatters: any[] = [];
  selectedMatters: any[] = [];
  isLoading: Boolean = false;
  constructor( private projectService: ProjectsGridService,private helper: helperService,private payrollService:PayrollTimeReportService) { }


  ngOnInit(): void {
    this.isLoading=true;

    this.summaryCols = [
      { field: 'userName', header: 'User Name' },
      { field: 'totaltime', header: 'Total Time (HH:MM)' },
      { field: 'totaltimeDecimal', header: 'Total Time (Decimal)' },
    ];
    this.nonSummaryCols= [
      { field: 'orgName', header: 'Organisation Name' },
      { field: 'matterName', header: 'Matter Name' },
      { field: 'projectName', header: 'Project Name'},
      { field: 'projTaskName', header: 'Task Name' },
      { field: 'trDateStr', header: 'Time Record Date' },
      { field: 'trDescription', header: 'Time Record Description' },
      { field: 'userName', header: 'User Name' },
      { field: 'totalProjTime', header: 'Total Project Time (HH:MM)' },
      { field: 'totalProjTimeDecimal', header: 'Total Project Time (Decimal)' },
      { field: 'totaltime', header: 'Total User Time (HH:MM)' },
      { field: 'totaltimeDecimal', header: 'Total User Time (Decimal)' },
      { field: 'totalTaskTime', header: 'Total Task Time (HH:MM)' },
      { field: 'totalTaskTimeDecimal', header: 'Total Task Time (Decimals)' },
      { field: 'loggedTrTime', header: 'Total Time Record Time (HH:MM)' },
      { field: 'loggedTrTimeDecimal', header: 'Total Time Record Time (Decimals)' }
    ];
    // this.exportColumns = this.summaryCols.map(col => ({title: col.header, dataKey: col.field}));

    this.projectService.getAllOrganizations().subscribe(res => {
      this.allOrganisations = res.data;
      this.selectedOrgs=res.data.map((obj:any)=>obj.id);
      this.payrollService.getMattersByOrgId({ "orgIds" : [] }).subscribe(res => {
        this.allMatters = res.data;
        this.selectedMatters = res.data.map((obj: any) => obj.matterId);
        this.isLoading=false;
      })
    })
    this.projectService.getLPCUsersList({
      "roles": ["TK"],
      // "userTypes": ["Internal"]
    }).subscribe(res => {
      this.allUsers = res.data;
      this.selectedUsers=res.data.map((obj:any)=>obj.id);
    })
    
  }

  fetchMattersByOrgIds(){
    this.isLoading=true;
    if(this.selectedOrgs && this.selectedOrgs.length > 0){
      this.payrollService.getMattersByOrgId({ "orgIds" : this.selectedOrgs }).subscribe(res => {
        this.allMatters = res.data;
        this.selectedMatters = res.data.map((obj: any) => obj.matterId);
        this.isLoading=false;
      })
    }else{
      this.allMatters = []
      this.selectedMatters = []
      this.isLoading=false;
    }
    
  }


  loadPayrollReportData(){
    this.submitted=true;
    if((!this.selectedDates||this.selectedDates.length==0)||(!this.selectedOrgs||this.selectedOrgs.length==0)||(!this.selectedUsers||this.selectedUsers.length==0)){
      return
    }
    this.reportSummaryList=[]
    this.reportNonSummaryList=[]
    let payload={
      startDate:this.helper.convertDateToFormattedStr(this.selectedDates[0]),
      endDate:this.helper.convertDateToFormattedStr(this.selectedDates[1]),
      orgIds:this.selectedOrgs.length==this.allOrganisations.length?[]:this.selectedOrgs,
      userIds:this.selectedUsers.length==this.allUsers.length?[]:this.selectedUsers,
      summaryOnly:this.isSummaryOnly,
      matterIds: this.selectedMatters
    }
    console.log(payload)
    this.generateLoader=true;
    this.isLoading = true;

    this.payrollService.getPayrollReports(payload).subscribe(res => {
      this.showSummaryOnly=this.isSummaryOnly
      let userList=[]
      if(res && res.data){
        let list=res.data
        for (let rec of list){
          if(rec.data){
            userList = this.flattenDetailsData(res.data);
          }else{
            rec.totaltimeDecimal=this.helper.convertTimeNumToDecimal(rec.totaltime);
            rec.totaltime=this.helper.convertTimeNumToStr(rec.totaltime);
          }
        }
        if(userList.length>0){
          this.reportNonSummaryList=userList
          // this.showSummaryOnly=false;
        }else{
          this.reportSummaryList= list
          // this.showSummaryOnly=true;
        }
      }
      this.generateLoader=false;
      this.isLoading = false;
      this.submitted=false;
    }, error => {
      this.generateLoader = false;
      this.isLoading = false;
      this.submitted=false;
    });
  }

  exportReport(table:Table){
    if(this.showSummaryOnly){
      table.exportFilename="Payroll Summary Time Report"
      table.exportCSV()
    }else{
      table.exportFilename="Payroll Detailed Time Report"
      table.exportCSV()
    }
  }

  flattenDetailsData(data:any){
    let flatArray:any=[]
    data.forEach((user:any) => {
      user?.data?.forEach((proj: any) => {
        proj?.projTasks?.forEach((task: any) => {
          task?.timeRecords?.forEach((record: any) => {
            flatArray.push({
              orgName: proj.orgName,
              matterName: proj.matterName,
              projectName: proj.projectName,
              userId: user.userId,
              userName: user.userName,
              projTaskId: task.projTaskId,
              projTaskName: task.projTaskName,
              projTaskFullName: task.projTaskFullName,
              taskCreatedOn: task.taskCreatedOn,
              trId: record.trId,
              trDescription: record.trDescription,
              trDate: new Date(record.trDate),
              trDateStr: record.trDate,
              loggedTrTime: this.helper.convertTimeNumToStr(record.loggedTime),
              loggedTrTimeDecimal: this.helper.convertTimeNumToDecimal(record.loggedTime),
              totalTaskTime: this.helper.convertTimeNumToStr(task.loggedTime),
              totalTaskTimeDecimal: this.helper.convertTimeNumToDecimal(task.loggedTime),
              totalProjTime: this.helper.convertTimeNumToStr(proj.loggedTime),
              totalProjTimeDecimal: this.helper.convertTimeNumToDecimal(proj.loggedTime),
              totaltime: this.helper.convertTimeNumToStr(user.totaltime),
              totaltimeDecimal: this.helper.convertTimeNumToDecimal(user.totaltime)
            });
          });
        });
      });
    });
    console.log(flatArray)
    return flatArray;
  }

}
