import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { MatterService } from '../matter.service';

@Component({
  selector: 'app-view-matter',
  templateUrl: './view-matter.component.html',
  styleUrls: ['./view-matter.component.scss']
})
export class ViewMatterComponent implements OnInit {

  submitted: boolean = false;
  matterForm: any = [];
  btnLoading = false;

  @Input()
  selectedRow: any;

  @Input()
  viewMatter:boolean=false;

  @Input()
  viewOnly: boolean = false;

  @Output() closeFlag=new EventEmitter<Boolean>();

  selectedStatus: any = '';
  statusDropdown = ['Active','Inactive']

  constructor( private readonly messageService: MessageService, private matterService: MatterService) { }

  ngOnInit(): void {
    this.matterForm = new FormGroup({

      id: new FormControl(''),
      orgName: new FormControl(''),
      statusCode: new FormControl('', [Validators.required]),
      name: new FormControl(''),
      orgQbId: new FormControl(''),
      number: new FormControl(''),
      // timeIncrement: new FormControl('', [Validators.required]),
      // invType: new FormControl('', [Validators.required]),
      isBillable: new FormControl(''),
      // notes: new FormControl('', [Validators.required]),

    });
    this.editMatter(this.viewOnly,this.selectedRow) 
  }
  
  editMatter(viewOnly:Boolean,matterObj: any) {
    if(viewOnly){
      this.matterForm.disable()
    }else{
      this.matterForm.enable()
    }
    matterObj.isBillable=matterObj.isBillable=='Y'?true:false;
    this.matterForm.patchValue(matterObj)
    
  }

  saveMatter() {
    if (this.btnLoading)
      return;
    this.submitted = true;
    if (this.matterForm.valid) {
      this.btnLoading = true;
      let payload = this.matterForm.value;
      delete payload.orgName
      delete payload.orgQbId
      delete payload.id
      payload.isBillable=payload.isBillable?'Y':'N';
      this.matterForm.disable();
      try {
        this.matterService.updateMatterDataById(this.matterForm.controls['id'].value, payload).subscribe(
          res => {
            this.btnLoading = false;
            if (res.message) {
              this.viewOnly = true;
              this.messageService.add({ severity: 'success', detail: 'Task ' + this.matterForm.controls['name'].value + ' details have been saved successfully' });
            } else {
              this.matterForm.enable();
              this.messageService.add({ severity: 'error', detail: res.message });
            }
          }, error => {
            this.btnLoading = false;
            this.matterForm.enable();
            console.log(error)
            this.messageService.add({ severity: 'error', detail: error.message });
          })
      } catch (error: any) {
        this.btnLoading = false;
        this.matterForm.enable();
        console.log(error)
        this.messageService.add({ severity: 'error', detail: error.message });
      }

    }
  }
  get statusCode() {
    return this.matterForm.get('statusCode');
  }
  get number() {
    return this.matterForm.get('number');
  }
  closeDialog(){
    this.closeFlag.emit(true);
  }


}
