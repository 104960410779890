import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeRecordService {

  private readonly timeRecordsUrl = environment.main_url + environment.timeRecordsUrl

  constructor(private readonly http: HttpClient) { }

  getTimeRecordsList(pageNumber: any, pageSize: any,dashboard:boolean, payload: any) {
    if (dashboard) {
      return this.http.post<any>(this.timeRecordsUrl + "/filter?pageNumber=" + pageNumber + "&pageSize=" + pageSize, payload)
    }
    else {
      return this.http.post<any>(this.timeRecordsUrl + "/menu/filter?pageNumber=" + pageNumber + "&pageSize=" + pageSize, payload)
      }
  }

  submitMultiple(payload:any){
    return this.http.put<any>(this.timeRecordsUrl , payload)
  }
  deleteTR(payload:any){
    return this.http.delete<any>(this.timeRecordsUrl,payload)
  }
  updateTR(payload:any,id:any){
    return this.http.put<any>(this.timeRecordsUrl+'/'+id,payload);
  }
}
