import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimeRecordExportService {

  private readonly batchTableUrl = environment.main_url + environment.batchTable
  private readonly downloadbatchUrl = environment.main_url + environment.downloadBatch

  constructor(private readonly http: HttpClient) { }
  getAllBatchList(payload:any){
    return this.http.post<any>(this.batchTableUrl+"/filter",payload)
  }
  generateExportData(){
    return this.http.post<any>(this.batchTableUrl,{})
  }
  downloadBatch(batchId:any){
    return this.http.get(this.downloadbatchUrl+batchId,{ responseType: 'arraybuffer', observe: 'response' })
  }
}
