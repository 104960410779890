import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TkAvailabilityService {

  private readonly tkAvailabilityUrl = environment.main_url + environment.tkAvailablity
  
  constructor(private readonly http: HttpClient) { }

  saveTask(payload:any){
      return this.http.post<any>(this.tkAvailabilityUrl,payload);  
  }
  
  getAllAvailabilityList(startDate:any,endDate:any,selectedUserId:any){
    // return this.http.get<any>(this.tkAvailabilityUrl+"/dashboard?startDate=01/01/2023&endDate=12/30/2023")
    if(selectedUserId)
      return this.http.get<any>(this.tkAvailabilityUrl+"/dashboard?startDate="+startDate+"&endDate="+endDate+"&userId="+selectedUserId)
    return this.http.get<any>(this.tkAvailabilityUrl+"/dashboard?startDate="+startDate+"&endDate="+endDate)
  }

  getAllTKAvailabilityUsers(startDate:any,endDate:any){
    // return this.http.get<any>(this.tkAvailabilityUrl+"/dashboard?startDate=01/01/2023&endDate=12/30/2023")
    return this.http.get<any>(this.tkAvailabilityUrl+"/dashboard/users?startDate="+startDate+"&endDate="+endDate)
  }

}
