import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private readonly allOrgsUrl = environment.main_url + environment.allOrgs


  constructor(  private readonly http: HttpClient) { }

  getAllOrganizations(pageNumber:number, pageSize:number,payload:any){

    return this.http.post<any>(this.allOrgsUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
    // return this.http.get<any>(this.allOrgsUrl+"?pageNumber="+pageNumber+"&pageSize="+pageSize)    
  }

  addOrganization(data:any){
    return this.http.post<any>(`${this.allOrgsUrl}`, data).pipe(
      map((result) => {
        return result;
      }),
      catchError((err) => {
        return of([]);
      }),
    );
  }

  updateOrgDataById(notes:any,id:any){
      return this.http.put<any>(this.allOrgsUrl+"/"+id,notes);  
  }
}
