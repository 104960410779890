import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [

    {
        id: 1,
        label: 'Dashboards',
        icon: '',
        subItems: [
            {
                id: 2,
                label: 'LPC',
                link: '/lpc-dashboard',
                parentId: 1
            },
            {
                id: 3,
                label: 'Timekeeper',
                link: '/timekeeper-dashboard',
                parentId: 1
            },
        ]
    },

    {
        id: 2,
        label: 'Organizations',
        icon: '',
        link: '/organization',
       
    },
    {
        id: 3,
        label: 'Matters',
        link: '/matters',
    },
    {
        id: 4,
        label: 'Projects',
        link: '/projects',

    },
    
   
    {
        id: 5,
        label: 'Tasks',
        icon: '',
        link:'/task-grid'
    },
    {
        id: 5,
        label: 'TK Availability',
        icon: '',
        link:'/tk-availability'
    },
    {
        id: 5,
        label: 'Time Records',
        icon: '',
        link:'/time-record'
    },
    {
        id: 6,
        label: 'Admin',
        icon: '',
        subItems: [
            {
                id: 7,
                label: 'Users',
                link: '/user-grid',
                parentId: 6
            },
            // {
            //     id: 8,
            //     label: 'TK Availability',
            //     link: '/tk-availability',
            //     parentId: 6
            // },
            {
                id: 9,
                label: 'Task Catalog',
                link: '/task-catalogue',
                parentId: 6
            },
            {
                id: 10,
                label: 'Projects Templates',
                link: '/projects-template',
                parentId: 6
            },
            {
                id: 11,
                label: 'Import Data',
                link: '/import-data',
                parentId: 6
            },
            {
                id: 12,
                label: 'Time Record Export',
                link: '/timerecord-export',
                parentId: 6
            },
            {
                id: 13,
                label: 'Application Configurations',
                link: '/app-config',
                parentId: 6
            }
        ]
       
    },
    {
        id: 14,
        label: 'Reports',
        icon: '',
        subItems: [
            {
                id: 15,
                label: 'Payroll Time Report',
                link: '/payroll-time-report',
                parentId: 14
            },
            {
                id: 16,
                label: 'Client Time Report',
                link: '/client-time-report',
                parentId: 14
            },
        ]
       
    },
   
];

