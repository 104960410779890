import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersGridService {
  [x: string]: any;

  constructor(private readonly http: HttpClient) { }
  private readonly getAllUsers = environment.main_url + environment.getAllUsers
  private readonly saveUser = environment.main_url + environment.saveUser
  private readonly updateUser = environment.main_url + environment.updateUser
  private readonly inactivate = environment.main_url + environment.inactivateUser
  private readonly getLoggedInUsers = environment.main_url + environment.getLoggedInUser
  private readonly PracticeArea = environment.main_url + environment.getPracticeArea

  getUsersList(pageNumber:any,pageSize:any,payload:any){
    return this.http.post<any>(this.getAllUsers+"?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
  }

  addUser(user:any){
    return this.http.post<any>(this.saveUser,user)
  }
  editUser(user:any){
    return this.http.post<any>(this.updateUser,user)
  }

  getLoginUser(){
    return this.http.post<any>(this.getLoggedInUsers,{})
  }

  getPracticeArea(){
    return this.http.get<any>(this.PracticeArea)
  }
  inactivateUser(user:any){
    return this.http.post<any>(this.inactivate,user)
  }

}
