import { HttpClient,HttpHeaders,HttpRequest  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskGridService {

  private readonly projectTasksUrl = environment.main_url + environment.projectTasks
  private readonly dashboardprojectTasksUrl = environment.main_url + environment.dashboardTasks
  private readonly taskFullNames=environment.main_url + environment.taskCatalog
  private readonly tkAvailability=environment.main_url + environment.tkAvailablity
  private readonly tkAssignment=environment.main_url + environment.tkAssignment
  private readonly acceptTask=environment.main_url + environment.acceptTask
  private readonly acceptedTks =environment.main_url + environment.acceptedtks
  private readonly savetr =environment.main_url + environment.timeRecordsUrl

  constructor(private readonly http: HttpClient) { }

  getProjectTaskList(pageNumber:any,pageSize:any,dashboard:boolean,payload:any){
    if(dashboard){
      return this.http.post<any>(this.dashboardprojectTasksUrl+"?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
    }
    else{
      return this.http.post<any>(this.projectTasksUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
    }
  }
  saveTask(payload:any,id:any){
      return this.http.put<any>(this.projectTasksUrl+"/"+id,payload);  
  }
  deleteTask(projectId:any,taskId:any){
    return this.http.delete<any>(this.projectTasksUrl+"/"+projectId+"/"+taskId);  

  }
  deleteTaskGrid(payload:any){
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const request = new HttpRequest('DELETE', this.tkAssignment, payload, {
      headers: headers,
    });

    return this.http.request(request);
  }
  
  getAllProjectTaskList(){
    return this.http.get<any>(this.projectTasksUrl)
  }

  getAllProjectTaskFullNameList(){
    return this.http.get<any>(this.taskFullNames+"/fetchTaskFullNames")
  }
  getTKUsersList(projTaskId:any,includeToday:any,isReassign:any){
    return this.http.get<any>(this.tkAvailability+"?projTaskId="+projTaskId+"&includeToday="+includeToday+"&isReassign="+isReassign)
  }

  assignTask(payload:any){
    return this.http.post<any>(this.projectTasksUrl,payload);  

  }
  assignTaskToTK(payload:any){
    return this.http.post<any>(this.tkAssignment,payload);  

  }
  acceptTastbyTK(payload:any){
    return this.http.post<any>(this.acceptTask,payload)
  }
  getDayWiseData(projectTaskId:any){
    return this.http.get<any>(this.tkAvailability+"/projTask/"+projectTaskId)
  }
  getTKAssignmentDetails(taskId: any) {
    return this.http.get<any>(this.tkAssignment+"/projTask/"+taskId);  

  }
  getAcceptedDaywise(projectTaskId:any){
    return this.http.get<any>(this.acceptTask+"/projTask/"+projectTaskId)
  }
  getAcceptedAdmin(projectTaskId:any){
    return this.http.get<any>(this.acceptedTks+"/projTask/"+projectTaskId)
  }
  saveTR(payload:any){
    return this.http.post<any>(this.savetr,payload)
  }
  getExistingTR(taskId:any){
    return this.http.get<any>(this.projectTasksUrl+"/"+taskId+"/timeRecords")
  }
  approveOrRejectTask(payload:any){
    return this.http.put<any>(this.projectTasksUrl+"/status",payload);
  }
  cloneTask(taskId:any){
    return this.http.post<any>(this.projectTasksUrl+"/"+taskId+"/clone",{});
  }
  
}
