import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationComponent } from './dashboards/organization/organization.component';
import { TimekeeperDashboardComponent } from './dashboards/timekeeper-dashboard.component';
import { LpcDashboardComponent } from './dashboards/lpc-dashboard/lpc-dashboard.component';
import { UsersGridComponent } from './users-grid/users-grid.component';
import { MattersComponent } from './dashboards/matters/matters.component';
import { TaskGridComponent } from './tasks-grid/task-grid.component';
import { TkAvailabilityComponent } from './tk-availability/tk-availability/tk-availability.component';
import { TaskCatalogueComponent } from './task-catalogue/task-catalogue.component';
import { ProjectsTemplateComponent } from './projects-template/projects-template.component';
import { ProjectsGridComponent } from './projects-grid/projects-grid.component';
import { ImportDataComponent } from './../import-data/import-data.component';
import { TimeRecordGridComponent } from './time-record-grid/time-record-grid.component';
import { TimeRecordExportComponent } from './time-record-export/time-record-export.component';
import { AuthGuard } from '../account/auth/app.gaurd';
import { AppConfigComponent } from './app-config/app-config.component';
import { PayrollTimeReportComponent } from './payroll-time-report/payroll-time-report.component';
import { ClientTimeReportComponent } from './client-time-report/client-time-report.component';

const routes: Routes = [
  {
    path: '',
    component: OrganizationComponent,
    canActivate:[AuthGuard],
    data:{
      roles:['']
    }
  },
  
  {
    path: 'organization',
    component: OrganizationComponent
  },
  {
    path: 'matters',
    component: MattersComponent
  },
  {
    path: 'projects',
    component: ProjectsGridComponent
  },
  {
    path: 'timekeeper-dashboard',
    component: TimekeeperDashboardComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN","TK"]
    }
  },
  {
    path: 'lpc-dashboard',
    component: LpcDashboardComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN","LPC"]
    }
  },
  {
    path: 'user-grid',
    component: UsersGridComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'task-catalogue',
    component: TaskCatalogueComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'projects-template',
    component: ProjectsTemplateComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'task-grid',
    component: TaskGridComponent
  },
  {
    path: 'tk-availability',
    component: TkAvailabilityComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["TK","ADMIN"]
    }
  },
  {
    path: 'time-record',
    component: TimeRecordGridComponent
  },
  {
    path: 'import-data',
    component: ImportDataComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'timerecord-export',
    component: TimeRecordExportComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'app-config',
    component: AppConfigComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'payroll-time-report',
    component: PayrollTimeReportComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
  {
    path: 'client-time-report',
    component: ClientTimeReportComponent,
    canActivate:[AuthGuard],
    data:{
      roles:["ADMIN"]
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }