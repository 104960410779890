import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientTimeReportService {

  private readonly payrollUrl = environment.main_url + environment.clientReports
  private readonly customMatterUrl = environment.main_url + environment.getMattersByOrgId
  constructor(private readonly http: HttpClient) { }

  getClientReports(payload:any){
      return this.http.post<any>(this.payrollUrl,payload); 
  }

  getMattersByOrgId(orgIds:any){
    return this.http.post<any>(this.customMatterUrl,orgIds)
  }
}
