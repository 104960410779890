import { FilterMatchMode } from "primeng/api";

export class helperService {
  generateEventPayload(event: any): any {
    let filters: any[] = [];
    let sort: any[] = [];
    let payload = {
      filters: filters,
      sort: sort
    }
    if(!event ||!event.filters)
      return payload;
    Object.keys(event.filters).forEach(val => {
      if (event.filters[val][0].value != null) {
        let filterKey='';
        switch(event.filters[val][0].matchMode){
          case FilterMatchMode.DATE_BEFORE  :
            filterKey='ltDate';
            break;
          case FilterMatchMode.DATE_IS :
            filterKey='dateIs';
            break;
          case FilterMatchMode.DATE_AFTER :
            filterKey='gtDate';
            break;
          default:
              break;
        }
        if(filterKey){
          let obj = {
            "field": val,
            "operator": filterKey,
            "value": this.convertDateToFormattedPayloadStr(event.filters[val][0].value)
          }
          payload.filters.push(obj);
        }
        else{
          let obj =null;
          if(event.filters[val][0].value.constructor === Array){
            if(event.filters[val][0].value.length>0)
            obj = {
              "field": val,
              "operator": "in",
              "values": event.filters[val][0].value.map((obj:any)=>obj.value),
            }
          }else{
            obj = {
              "field": val,
              "operator": event.filters[val][0].matchMode,
              "value": event.filters[val][0].value
            }
          }
        if(obj)
          payload.filters.push(obj);
      }
        
      }
    })
    if (event.sortField) {
      payload.sort = [{
        field: event.sortField,
        order: event.sortOrder > 0 ? "DESC" : "ASC"
      }]
    }
    return payload;
  }

  convertTimeToDate(timeNumber: any): any {
    if (!timeNumber) return null;
    let date = new Date();
    const [hours, minutes] = (String(Math.floor(timeNumber / 60)) + ':' + String(timeNumber % 60)).split(':');
    date.setHours(Number(hours), Number(minutes));
    return date;
  }

  convertDateToTime(date: Date): any {
    return date ? date.getHours() * 60 + date.getMinutes() : null
  }

  convertTimeNumToStr(timeNumber: any): any {
    if (!timeNumber) return "00:00";
    if (timeNumber < 0) return "00:00";
    let hours = String(Math.floor(timeNumber / 60)).length <= 1 ? "0" + String(Math.floor(timeNumber / 60)) : String(Math.floor(timeNumber / 60))
    let mins = String(timeNumber % 60).length <= 1 ? "0" + String(timeNumber % 60) : String(timeNumber % 60)
    return (hours + ':' + mins);
  }
  convertTimeNumToDecimal(timeNumber: any): any {
    if (!timeNumber) return 0;
    if (timeNumber < 0) return 0;
    let hours = Math.floor(timeNumber / 60)
    let mins = timeNumber % 60
    return (hours + (mins / 60)).toFixed(2);
  }

  convertStrToNum(str: any): any {
    if (!str) return null;
    let hours = str.split(":")[0] && Number(str.split(":")[0]) ? Number(str.split(":")[0]) : 0;
    let mins = str.split(":")[1] && Number(str.split(":")[1]) ? Number(str.split(":")[1]) : 0;
    return hours * 60 + mins
  }

  convertDateToFormattedStr(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      (date_Object.getMonth() + 1 < 10 ? "0" + (date_Object.getMonth() + 1) : date_Object.getMonth() + 1)
      + "/"
      + (date_Object.getDate() < 10 ? "0" + date_Object.getDate() : date_Object.getDate())
      + "/"
      + date_Object.getFullYear()

    return date_String;
  }
  convertDateToFormattedPayloadStr(date_Object: Date): string {
    // get the year, month, date, hours, and minutes seprately and append to the string.
    let date_String: string =
      
       date_Object.getFullYear()
      +"-"
      +(date_Object.getMonth() + 1 < 10 ? "0" + (date_Object.getMonth() + 1) : date_Object.getMonth() + 1)
      +"-"
      +(date_Object.getDate() < 10 ? "0" + date_Object.getDate() : date_Object.getDate())

    return date_String;
  }

  compareTime(time1: any, time2: any) {
    return this.convertDateToTime(time1) >= this.convertDateToTime(time2) ? true : false
  }

  isPastDate(date: any) {
    if(date.getDate() < new Date().getDate() && date.getMonth() <= new Date().getMonth() && date.getFullYear() <= new Date().getFullYear())//skip all previous days 
        return true;
    if(date.getMonth() < new Date().getMonth() && date.getFullYear() <= new Date().getFullYear())//skip all previous months
      return true;
    if(date.getFullYear() < new Date().getFullYear())//skip all previous years
      return true;

    return false;
  }

  getDayDiff(dateSent:Date,currentDate:Date):number{
    return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateSent.getFullYear(), dateSent.getMonth(), dateSent.getDate()) ) /(1000 * 60 * 60 * 24));
  }

  compareDate(date1:Date,date2:Date):number{
    /*
      date1 < date2 => returns -1
      date1 = date2 => returns 0
      date1 > date2 => returns 1
    */
    if(date1.getDate() < date2.getDate() && date1.getMonth() <= date2.getMonth() && date1.getFullYear() <= date2.getFullYear())//skip all previous days 
        return -1;
    if(date1.getMonth() < date2.getMonth() && date1.getFullYear() <= date2.getFullYear())//skip all previous months
      return -1;
    if(date1.getFullYear() < date2.getFullYear())//skip all previous years
      return -1;
    if(date1.getDate() == date2.getDate())
      return 0;
    return 1;
  }
  isWeekend(date:Date){
    return date.getDay() !== 0 && date.getDay() !== 6?false:true
  }
  isSaturday(date:Date){
    return  date.getDay() !== 6?false:true
  }
  isSunday(date:Date){
    return date.getDay() !== 0 ?false:true
  }


  useDashboardUrl(roles:any,isDashbaord:Boolean){
    let useDashboardUrlFlag=false
    if(this.isOnlyTKUser(roles)){
      useDashboardUrlFlag=true;
    }
    else{
      useDashboardUrlFlag=isDashbaord?true:false;
    }
    return useDashboardUrlFlag;
  }

  isOnlyTKUser(list:any[]){
    if(!this.isAdminUser(list) && !this.isLPCUser(list)){
      if (list.includes("TK") ) {
        return true;
      }
    }
    return false;
  }

  isAdminUser(list:any[]){
    if (list.includes("ADMIN") ) {
      return true;
    }
    return false;
  }
  isLPCUser(list:any[]){
    if (list.includes("LPC") ) {
      return true;
    }
    return false;
  }

  getStrBoolean(bool:any){
    if(typeof(bool)=="string"){
      return bool && bool.toLocaleUpperCase()=="YES"?"Yes":"No";
    }else{
      return bool==true?"Yes":"No";
    }
  }

  getflagBoolean(bool:any){
    if(typeof(bool)=="string"){
      return bool && bool.toLocaleUpperCase()=="YES"?true:false;
    }else{
      return bool==true?true:false;
    }
  }
}