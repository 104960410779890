import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    public readonly keycloak: KeycloakService,
  ) {
    super(router, keycloak);
  }

  jwtData:any;
  roles:any;


  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // Force the user to log in if currently unauthenticated.

    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin 
        + state.url
      });
    }

    const jwtToken = await this.keycloak.getToken();
    const data = jwt_decode(jwtToken);
    this.jwtData = data;
    this.roles = this.jwtData.resource_access.agile_legal_ui.roles

    console.log(this.roles);
    // console.log(jwt_decode(this.keycloak.getToken()))

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }
    if (requiredRoles.includes("")){
      if(this.roles.includes("TK")){
        this.router.navigate(['/timekeeper-dashboard'],{ skipLocationChange: true });
      }else if(this.roles.includes("LPC")){
        this.router.navigate(['/lpc-dashboard'],{ skipLocationChange: true });
      }
      return true;
    }
    if (!requiredRoles.map((role) => this.roles.includes(role)).includes(true)) {
      this.router.navigate(['/error'],{ skipLocationChange: true });
      return true;
    }
    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.map((role) => this.roles.includes(role)).includes(true);
  }
}