// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  defaultauth: 'fakebackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  realm_name:"agile-legal-prod",
  client_id:'agile_legal_ui',
  main_url : 'https://api.agilelegal.com',
  // main_url : 'http://agile-prod-266972879.us-east-2.elb.amazonaws.com',
  keycloak_url:'https://login.agilelegal.com/auth',
  // keycloak_url:'http://keycloak-agile-prod-554077361.us-east-2.elb.amazonaws.com/auth',
  allOrgs : '/agilelegal/organizations',
  getAllUsers : '/agilelegal/user/listUsers',
  saveUser:'/agilelegal/user/addUser',
  getLoggedInUser:'/agilelegal/user/getLoginUserDetails',
  getAllMatters:'/agilelegal/matters',
  updateUser:'/agilelegal/user/updateUser',
  inactivateUser:'/agilelegal/user/inactivateUser',
  taskCatalog:'/agilelegal/tasks',
  projectTemplateTasks:'/agilelegal/projectTemplateTasks',
  projectTemplate:'/agilelegal/projectTemplate',
  projectList:'/agilelegal/projects',
  dashboardProjects:'/agilelegal/tkDashboard/projects',
  projectTasks:'/agilelegal/projectTasks',
  projectTypesList:'/agilelegal/category/projectTypes',
  lpcUsersList:'/agilelegal/user/filter',
  tkAvailablity:"/agilelegal/tkAvailability",
  getImportData:'/agilelegal/fileUpload/filter',
  uploadData:'/agilelegal/fileUpload/uploadOrgFile',
  tkAssignment:'/agilelegal/tkAssignment',
  acceptTask:'/agilelegal/tkAssignment/acceptance',
  dashboardTasks:"/agilelegal/tkDashboard/tasks/filter",
  acceptedtks:'/agilelegal/tkAssignment/tks',
  timeRecordsUrl:'/agilelegal/timeRecords',
  batchTable:'/agilelegal/export/timerecords',
  downloadBatch:'/agilelegal/export/timerecords/batch/',
  appconfigUrl:'/agilelegal/appConfiguration' ,
  getPracticeArea:'/agilelegal/practiceArea' ,
  payrollReports:'/agilelegal/reports/payroll',
  clientReports:'/agilelegal/reports/client',
  getMattersByOrgId:'/agilelegal/matters/orgs'
};
