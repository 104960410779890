

import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterMatchMode, SelectItem } from 'primeng/api';
import { TaskCatalogueService } from '../task-catalogue/task-catalogue.service';
import { ProjectTemplateService } from './project-template.service';
import { helperService } from 'src/app/utils/helper';
import { matchModeOptionsDateList } from 'src/app/utils/filterDropDownData';
import { projectTemplateList } from 'src/app/utils/filterDropDownData'; 
import {userTkPracticeAreas,  } from 'src/app/utils/userData';
import { UsersGridService } from 'src/app/pages/users-grid/users-grid.service';


@Component({
  selector: 'app-projects-template',
  templateUrl: './projects-template.component.html',
  styleUrls: ['./projects-template.component.scss']
})

export class ProjectsTemplateComponent implements OnInit {

  addTemplateName: String = "";
  templateDialogue: boolean = false;
  editDialogue: boolean = false;
  viewOnly: boolean = false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptionsDate: SelectItem[]=matchModeOptionsDateList;
  matchModeOptionsNumber?: SelectItem[];
  title: string = "";
  loading: boolean = true;
  totalRecords: number = 0;
  first: number = 1;
  newTask: any = "Select";
  statusList:any;
  newTempDialogue:boolean=false;
  projectTemplateName:String="";
  practiceArea:any;
  allTemplates: any[] = [];
  tkAreas: any = [];
  selectedTemplate: any = {
    projectTemplate: {
      id: "",
      description: "",
      status:"",
      practiceArea:""
    },
    tasks: [],
    unassignedTasks: []
  };
  tpltLoading: boolean = false;
  assignLoading: boolean = false;
  private lastTableLazyLoadEvent: any = null;

  constructor(private projectTemplateService: ProjectTemplateService,
    private taskCatService: TaskCatalogueService,private filterService:helperService,private userService: UsersGridService) { }

  ngOnInit(): void {
    this.tkAreas = this.getTkPracticeArea();

    this.matchModeOptionsString = [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ];


    this.matchModeOptionsNumber = [
      { label: 'Equal', value: FilterMatchMode.EQUALS },
      { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
      { label: 'Less than', value: FilterMatchMode.LESS_THAN },
      { label: 'Between', value: FilterMatchMode.BETWEEN },
    ];
    this.statusList=projectTemplateList.statusList;
  }

  addTemplate(template:any,disable:Boolean) {
    if (!template && !this.addTemplateName  && !this.selectedTemplate.projectTemplate.id)
      return;
    
    let payload:any={};
    if(template){
      this.selectedTemplate=template;
    }
    if(!this.selectedTemplate.projectTemplate.id){
       payload = { "projectTemplateName": this.addTemplateName }
    }else{
      payload=this.selectedTemplate.projectTemplate;
    }

    if(disable){
      if(template.projectTemplate.status=="Inactive"){
        payload.status="Active"
      }else{
        payload.status="Inactive"
      }
    }

    try {
      this.projectTemplateService.createTemplate(payload, this.selectedTemplate.projectTemplate.id).subscribe((res: any) => {
        this.addTemplateName = "";
        this.editDialogue=false;
        this.loadTemplateData(this.lastTableLazyLoadEvent);
      })
    } catch (error) {
      console.log(error);
    }
  }
  getTkPracticeArea(){
    this.userService.getPracticeArea().subscribe(res=>{
        this.tkAreas=res.data;
    })
    // let tkAreas=userTkPracticeAreas;
    return this.tkAreas;
  }

  closeDialogue() {
    this.selectedTemplate={
      projectTemplate: {
        id: "",
        description: "",
        practiceArea:""
      },
      tasks: [],
      unassignedTasks: []
    };
    this.newTempDialogue=false;
    this.loadTemplateData(this.lastTableLazyLoadEvent);
  }
  loadTemplateData(event: any) {
    this.loading = false;
    this.lastTableLazyLoadEvent = event;
    this.loading = true;
    let payload=this.filterService.generateEventPayload(event);
    event.first = event.first ? event.first : 1;
    event.rows = event.rows ? event.rows : 10;
    this.projectTemplateService.getTemplateList(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe(res => {
      
      this.allTemplates = res.data;
      this.loading = false;
      this.totalRecords = res.customValues.totalNumberOfRecords;
    })
  }
  
  addTemplateTask() {
    if (!this.selectedTemplate.projectTemplate.id || !this.selectedTemplate.projectTemplate.description)
      return;
    this.assignLoading = true;
    let taskIds = this.selectedTemplate.tasks ? this.selectedTemplate.tasks.map((o: any) => o.id) : [];
    taskIds.push(this.newTask.id);
    const payload = {
      "projectTemplateName": this.selectedTemplate.projectTemplate.description,
      "taskIds": taskIds
    };
    try {

      this.projectTemplateService.assignTask(payload, this.selectedTemplate.projectTemplate.id).subscribe((res: any) => {
        this.assignLoading = false;
        let tempObj=this.transformTaskFullName(res.data)
        this.selectedTemplate.tasks = tempObj.tasks;
        this.selectedTemplate.unassignedTasks = tempObj.unassignedTasks  ? tempObj.unassignedTasks : this.selectedTemplate.unassignedTasks.filter((obj: any) => obj.id != this.newTask.id);
        this.newTask = "Select";

      })
    } catch (error) {
      this.assignLoading = false;
      console.log(error);
    }
  }
  newTemplate(){
    this.title='New Template';
    this.newTempDialogue=true;
  }
  addNewTemplate(){
      if(this.projectTemplateName && this.practiceArea){
        let payload={
          'projectTemplateName':this.projectTemplateName,
          'practiceArea':this.practiceArea.description
        }
        try {
          this.projectTemplateService.createTemplate(payload, this.selectedTemplate.projectTemplate.id).subscribe((res: any) => {
            this.closeDialogue();
          })
        } catch (error) {
          console.log(error);
        }
      }
  }

  editTemplate(template: any) {
    this.title = "Edit Template" ;
    this.viewOnly = false;
    this.editDialogue = true;
    // let tempObj=this.transformTaskFullName(template)
    this.selectedTemplate=template;
  }
  openTaskAssignment(template: any) {
    this.title = "Assign Task To " + template.projectTemplate.description;
    this.loading=true;
    this.selectedTemplate.projectTemplate=template.projectTemplate;
    try {
      this.projectTemplateService.getTemplateTasksList(template.projectTemplate.id).subscribe((res: any) => {
        console.log(res);
        this.loading=false;
        this.viewOnly = false;
        this.templateDialogue = true;
        let tempObj=this.transformTaskFullName(res.data)
        this.selectedTemplate.tasks = tempObj.tasks;
        this.selectedTemplate.unassignedTasks = tempObj.unassignedTasks  ? tempObj.unassignedTasks : this.selectedTemplate.unassignedTasks.filter((obj: any) => obj.id != this.newTask.id);
      })
    } catch (error) {
      this.assignLoading=false;
      console.log(error);
    }
  }
  transformTaskFullName(template:any){
    for (let k = 0; k < template.unassignedTasks.length; k++) {
      let str = "";
      let taskFullName=template.unassignedTasks[k].taskFullName?template.unassignedTasks[k].taskFullName:"";
      for (let j = 1; j < taskFullName.split(":").length; j++) {
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
        str = str + String.fromCharCode(160);
      }
      template.unassignedTasks[k].taskName=str+template.unassignedTasks[k].taskName;
      template.unassignedTasks[k].disabled=template.unassignedTasks[k].isHeader=="Yes"?true:false;
    } 
    return template;
  }

  viewTemplate(template: any) {
    this.title = "View " + template.projectTemplate.description;
    this.viewOnly = true;
    this.selectedTemplate=template;
    try {
      this.projectTemplateService.getTemplateTasksList(template.projectTemplate.id).subscribe((res: any) => {
        console.log(res);
        this.loading=false;
        this.templateDialogue = true;
        let tempObj=this.transformTaskFullName(res.data)
        this.selectedTemplate.tasks = tempObj.tasks;
        this.selectedTemplate.unassignedTasks = tempObj.unassignedTasks  ? tempObj.unassignedTasks : this.selectedTemplate.unassignedTasks.filter((obj: any) => obj.id != this.newTask.id);
      })
    } catch (error) {
      this.loading=false;
      console.log(error);
    }
  }

  deleteTask(task: any) {
    if (!this.selectedTemplate.projectTemplate.id)
      return;
    this.assignLoading=true;
    const payload = {
      "projectTemplateName": this.selectedTemplate.projectTemplate.description,
      "taskIds": this.selectedTemplate.tasks.filter((obj: any) => obj.id != task.id).map((o: any) => o.id)
    };
    try {
      this.projectTemplateService.assignTask(payload, this.selectedTemplate.projectTemplate.id).subscribe((res: any) => {
        this.assignLoading=false;
        let tempObj=this.transformTaskFullName(res.data)
        this.selectedTemplate.tasks = tempObj.tasks;
        this.selectedTemplate.unassignedTasks = tempObj.unassignedTasks ? tempObj.unassignedTasks : this.selectedTemplate.unassignedTasks.filter((obj: any) => obj.id != task.id);
        this.newTask = "Select";
      })
    } catch (error) {
      this.assignLoading=false;
      console.log(error);
    }
  }
 

}