import { Component, OnInit } from '@angular/core';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private auth: AuthGuard) { }

  ngOnInit(): void {
  }

  logout(event:any){
    event.preventDefault();
    this.auth.keycloak.logout();
  }

}
