import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, EventClickArg,  FullCalendarComponent } from '@fullcalendar/angular';
import { DecimalPipe } from '@angular/common';
import { helperService } from 'src/app/utils/helper';
import { TkAvailabilityService } from '../../tk-availability/tk-availability/tk-availability.service';

@Component({
  selector: 'app-lpc-dashboard',
  templateUrl: './lpc-dashboard.component.html',
  styleUrls: ['./lpc-dashboard.component.scss'],
  providers: [ DecimalPipe],
  encapsulation: ViewEncapsulation.None

})
export class LpcDashboardComponent implements OnInit {
  @ViewChild("calendar", { static: false })
  calendarComponent!: FullCalendarComponent;
  date: any;
  title: any = "";
  start: any = "";
  end: any = "";
  usersDialog: Boolean = false;
  isLoading: Boolean = false;
  loadingTKData: Boolean = false;
  tkUsers: any = []
  events: any = [
  ]
  // bread crumb items
  breadCrumbItems!: Array<{}>;
  isCollapsed = [false, false, false, false, false, false, false, false];
  alerts = [{
    name: 'Tasks Needing Reassignment'
  },
  {
    name: ' Tasks Needing Review'
  },
  {
    name: 'Projects Past Due'
  },
  {
    name: 'Tasks Needing Approval'
  }, {
    name: 'Past Due Tasks'
  }
    , {
    name: 'Tasks approaching Limit'
  }
    , {
    name: 'Tasks approaching Limit'
  },
  {
    name: 'TBD'
  }]

  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prevYear,prev',
      center: 'title',
      right: 'today,next,nextYear'
    },
    initialView: "dayGridWeek",
    firstDay: 0,
    themeSystem: "bootstrap",
    initialEvents: this.events,
    eventContent: this.customEventContent.bind(this),
    datesSet: this.handleMonthChange.bind(this),
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: false,
    dayMaxEvents: false,
    contentHeight: 'auto'
  };

  constructor(private filterService: helperService, private availablityService: TkAvailabilityService, private _cdr: ChangeDetectorRef) {

  }

  async handleMonthChange(payload: any) {
    this.setCalendarOptions([]);
    let start = this.filterService.convertDateToFormattedStr(payload.view.currentStart)
    let end = this.filterService.convertDateToFormattedStr(payload.view.currentEnd)
    this.loadTkUsersList(start, end);
  }

  loadTkUsersList(start: any, end: any) {
    this.start = start ? start : this.start;
    this.end = end ? end : this.end;
    this.isLoading = true;
    this.availablityService.getAllTKAvailabilityUsers(start, end).subscribe(res => {
      let grpList: any = []
      let list: any = []


      grpList = res.data.reduce((group: { [key: string]: any }, item: any) => {
        if (!group[item.availableDate]) {
          group[item.availableDate] = [];
        }
        group[item.availableDate].push(item);
        return group;
      }, {});

      Object.keys(grpList).forEach((key: any) => {
        let elementList = grpList[key];
        let usersList: any = []
        elementList.forEach((item: any) => {
          usersList.push(item.userDetails)
        })
        list.push({ title: "Total No of Users : " + elementList.length, date: key, color: '#3d82b9', tkUsers: elementList })
      });
      this.events = list;
      this.setCalendarOptions(list);
    })
  }
  handleEventClick(clickInfo: EventClickArg) {
    // console.log(clickInfo.event)
    this.usersDialog = true;
    this.title = "Users: " + clickInfo.event.toJSON().start
    this.tkUsers = clickInfo.event.toJSON().extendedProps.tkUsers;
  }

  setCalendarOptions(list: any) {
    this.calendarOptions.events = [];
    this.calendarOptions = {
      headerToolbar: {
        left: 'prevYear,prev',
        center: 'title',
        right: 'today,next,nextYear'
      },
      initialView: "dayGridWeek",
      firstDay: 0,
      themeSystem: "bootstrap",
      events: list,
      eventContent: this.customEventContent.bind(this),
      contentHeight: "auto",
      datesSet: this.handleMonthChange.bind(this),
      eventClick: this.handleEventClick.bind(this),
      weekends: true,
      editable: false,
      selectable: true,
      selectMirror: false,
      dayMaxEvents: false
      // contentHeight: 280
    };
    this.isLoading = false;
  }

  customEventContent(arg: any) {
    //add time
    let timeText = document.createElement('div')

    // if (arg.event.extendedProps.availableTimeValue) {
    //   timeText.className = "fc-event-time";
    //   timeText.innerHTML = this.getTimeKey(arg.event.extendedProps.availableTimeValue);
    // }
    //include additional info
    let additionalText = document.createElement('i')
    additionalText.className = "fc-event-title";
    additionalText.innerHTML = arg.event.title;

    let arrayOfDomNodes = [timeText, additionalText]
    return { domNodes: arrayOfDomNodes }
  }


  ngOnInit(): void {
    //BreadCrumb 
    this.breadCrumbItems = [
      { label: 'Dashboard' },
      { label: 'Dashboard', active: true }
    ];
  }

  expandAll() {
    this.isCollapsed = this.isCollapsed.map(item => {
      item = false
      return item;
    });
  }

  collapseAll() {
    this.isCollapsed = this.isCollapsed.map(item => {
      item = true
      return item;
    });
  }

  ngAfterViewInit() {

  }

  onAvailabilityChange(event: any) {
    let year = this.date.getFullYear();
    let month = this.date.getMonth() + 1 <= 9 ? '0' + (this.date.getMonth() + 1) : (this.date.getMonth() + 1);
    let day = this.date.getDate() <= 9 ? '0' + this.date.getDate() : this.date.getDate();
    let finalDate = year + "-" + month + "-" + day;
    let calendarApi = this.calendarComponent.getApi();
    calendarApi.gotoDate(finalDate);
  }


}
