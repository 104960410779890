import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectTemplateService {

  private readonly projectTemplateUrl = environment.main_url + environment.projectTemplate
  private readonly projectTemplateTasksUrl = environment.main_url + environment.projectTemplateTasks
  
  constructor(private readonly http: HttpClient) { }

  getTemplateList(pageNumber:any,pageSize:any,payload:any,noPages?:Boolean){
    if(noPages){
      return this.http.post<any>(this.projectTemplateTasksUrl+'/filter',payload); 
    }
    return this.http.post<any>(this.projectTemplateTasksUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
  }

  createTemplate(payload:any,id:any):any{
    if(id)
      return this.http.put<any>(this.projectTemplateUrl+"/"+id,payload);
    return this.http.post<any>(this.projectTemplateTasksUrl,payload);
  }

  assignTask(payload:any,id:any):any{
    if(id)
      return this.http.put<any>(this.projectTemplateTasksUrl+"/"+id,payload);
  }

  getTemplateTasksList(id:any){
      return this.http.get<any>(this.projectTemplateTasksUrl+'/'+id); 
  }

  // updateTemplate(payload:any):any{
  //   return this.http.put<any>(this.projectTemplateUrl,payload);
  // }
}
