import { Component, OnInit } from '@angular/core';
import { helperService } from 'src/app/utils/helper';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import {ImportDataService} from '../import-data/import-data.service';
import {ImportData,FailedImportData} from './import-data.model';
import { DatePipe } from '@angular/common';
import { importData, matchModeOptionsDateList } from '../utils/filterDropDownData';

import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit {
  
  
  private lastTableLazyLoadEvent: any=null;
  loading:boolean = true;
  totalRecords:number=0;
  importdata: ImportData[]=[];
  uploadDialog:boolean=false;
  uploadLoading:boolean=false;
  cols: any[] =[];
  failedcols: any[] =[];
  uploadForm:any={};
  title: string='';
  formData=new FormData();
  uplo:any;
  failedDialog:boolean=false;
  failedclickedid: any;
  failedData:FailedImportData[]=[];
  totalFailedRecords: number=0;
  matchModeOptionsNumber?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  statusList:any;
  myfiles:any[]=[];
  gridCustomMessage:any=''
    
  constructor( private readonly messageService: MessageService,private importservice:ImportDataService,private filterService:helperService,private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.cols = [
      { field: 'id', header: 'ID' },
      { field: 'name', header: 'Name' },
      { field: 'type', header: 'Type' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'status', header: 'Status' },
      { field: 'processedOn', header: 'Processed On' },
  ];
    this.failedcols = [
      { field: 'type', header: 'type' },
      { field: 'exceptionStackTrace', header: 'exceptionStackTrace' },
      { field: 'json', header: 'json' },
  ];
  this.uploadForm=new FormGroup({
    type: new FormControl(''),
    file:new FormControl(''),
  });
  this.matchModeOptionsNumber = [
    { label: 'Equal', value: FilterMatchMode.EQUALS },
    { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
    { label: 'Less than', value: FilterMatchMode.LESS_THAN },
    // { label: 'Between', value: FilterMatchMode.BETWEEN },
  ];
  this.matchModeOptionsDate=matchModeOptionsDateList;
  this.statusList=importData.statusList;
}

  loadImportData(event: LazyLoadEvent){
    this.lastTableLazyLoadEvent = event;
    this.loading= true;
    
    event.first=event.first?event.first:1;
    event.rows=event.rows?event.rows:10;
    let payload=this.filterService.generateEventPayload(event)
    this.importservice.getImportList(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe((res:any) => {
      this.loading = false;
      this.importdata=res.data;
      this.totalRecords=res.customValues.totalNumberOfRecords;
    },res => {
      this.loading = false;
      this.importdata=[]
      this.gridCustomMessage=res.error.message?res.error.message:''
      console.log(res)
    })
  }

  onUpload(event:any){
    if(!this.uploadForm.type){
      this.messageService.add({ severity: 'error', detail: "Please select Import Type to Upload." });
      return;
    }
    this.uploadLoading=true;
    try {
      for (let file of event.files) {
        this.uplo=file;
      }
      var reader = new FileReader();
      reader.onloadend = (e) => {
        this.callupload(this.uplo,reader.result?reader.result:'')
      };
      reader.readAsDataURL(this.uplo);
    } catch (error) {
      this.uploadLoading=false;
      this.messageService.add({ severity: 'error', detail: "Failed to upload File, Please Try Again." });
    }
  }

  callupload(file:any,byteStr:any){
    let payload={
      "name":file.name,
      "extension":"IIF",
      "byteString":byteStr.split(",")[1],
      "processedOn":this.datePipe.transform(new Date(), 'MM/dd/yyyy\'T\'HH:mm:ss.SSS'),
      "type":this.uploadForm.type,
      "status":"NEW"
    }

    this.importservice.uploadfile(payload).subscribe((res:any)=>{
      this.uploadLoading=false;
      this.myfiles=[];
      this.messageService.add({ severity: 'success', detail: "Uploaded SuccessFully" });
      console.log(res);
    }, res => {
      this.uploadLoading=false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    });
  }

  loadFailedData(event: LazyLoadEvent){
    this.loading=true;
  }
  failclick(col:any){
    console.log("clicked failed",col)
    let x=col.failedDomains;
    console.log(x);
    if(x.length>0){
    for(var record of col.failedDomains){
      let json:any;
      if(col.type=="USER"){
        json=record.userJson;
      }
      else if(col.type=="ORGANIZATION"){
        json=record.orgJson;
      }
      else{
        json=record.taskJson;
      }
      console.log(json)
      this.failedData.push({
        "type":col.type,
        "exceptionStackTrace":record.exceptionStackTrace,
        "json":JSON.stringify(json)
      })
    }
    }
    this.totalFailedRecords=this.failedData.length;
    this.failedDialog=true;
    console.log("faield data",this.failedData)
  }
  selecttype(type:any){
    this.uploadForm.type=type
  }
  

  viewUploadDialog(){
    this.uploadDialog=true;
    this.title="Upload";
  }

  closeDialog():void{
    this.uploadDialog=false;
    this.failedDialog=false;
    this.myfiles=[];
    this.messageService.clear();
    this.loadImportData(this.lastTableLazyLoadEvent);
  }
}
