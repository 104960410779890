

const userRoles=[
    {key: 'ADMIN', value: 'ADMIN'},
    {key: 'LPC', value: 'LPC'},
    {key: 'PM', value: 'PM'},
    {key: 'Approver', value: 'Approver'},
    {key: 'Client', value: 'Client'},
    {key: 'TK', value: 'TK'}
];

const userTimeZones=["CST","EST","MST","PST"];

const userTkPracticeAreas=[
    {key: 'Contract Management Services', value: 'Contract Management Services'},
    {key: 'Bankruptcy', value: 'Bankruptcy'},
    {key: 'Corporate Services', value: 'Corporate Services'},
    {key: 'Director Services', value: 'Director Services'},
    {key: 'Family Law', value: 'Family Law'},
    {key: 'Finance Services', value: 'Finance Services'},
    {key: 'Fund Services', value: 'Fund Services'},
    {key: 'Immigration Services', value: 'Immigration Services'},
    {key: 'Intellectual Property', value: 'Intellectual Property'},
    {key: 'International Services', value: 'International Services'},
    {key: 'KYC/AML', value: 'KYC/AML'},
    {key: 'Labor & Employment', value: 'Labor & Employment'},
    {key: 'Legal Project Services', value: 'Legal Project Services'},
    {key: 'Legal Research', value: 'Legal Research'},
    {key: 'Litigation', value: 'Litigation'},
    {key: 'Treasury Services', value: 'Treasury Services'},
    {key: 'Trusts & Estates', value: 'Trusts & Estates'}];

const userPmPracticeAreas=['Contract Management Services','Bankruptcy','Corporate Services','Director Services','Family Law','Finance Services','Fund Services','Immigration Services','Intellectual Property','International Services','KYC/AML','Labor & Employment','Legal Project Services','Legal Research','Litigation','Treasury Services','Trusts & Estates'];

const userTypes=["Paralegal Specialist","Employee"];

export {userRoles,userTimeZones,userTkPracticeAreas,userPmPracticeAreas,userTypes};