import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private readonly appconfigUrl = environment.main_url + environment.appconfigUrl


  constructor(  private readonly http: HttpClient) { }

  getAllConfigurations(pageNumber:number, pageSize:number,payload:any){
    return this.http.post<any>(this.appconfigUrl+"/filter?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
  }

  getConfigurationsById(id:any){
    if(id)
      return this.http.get<any>(this.appconfigUrl+"/"+id);
    return this.http.get<any>(this.appconfigUrl);
  }

  updateConfig(payload:any){
    return this.http.put<any>(this.appconfigUrl+"/"+payload.id,payload);
  }
}
