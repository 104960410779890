import { ChangeDetectorRef, Component, Input,OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import { helperService } from 'src/app/utils/helper';
import { MatterService } from './matter.service';
import { matchModeOptionsDateList, matter } from 'src/app/utils/filterDropDownData';

@Component({
  selector: 'app-matters',
  templateUrl: './matters.component.html',
  styleUrls: ['./matters.component.scss']
})
export class MattersComponent implements OnInit {

  @Input()
  matterOrgFilter:any=null;
  matterData: any;
  cols: any[] = [];
  statuses: any = [];
  matchModeOptionsString?: SelectItem[];
  matchModeOptions?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  matchModeOptionsNumber?: SelectItem[];
  loading = false;
  currentPageNumber = 1
  totalNumberOfPages = 0
  totalNumberOfRecords = 0
  rows = 10
  title: string = "";
  matterDialog: boolean = false;
  viewOnly: boolean = false;
  disableEdit:boolean=false;
  viewMatter:boolean=false;
  viewMatterFilterName:string="";
  totalRecords: number = 0;
  selectedRow:any={};
  viewMatterName:any;

  private lastTableLazyLoadEvent: any = null;
  projectMatterFilter: any;
  constructor( private readonly messageService: MessageService, 
    private matterService: MatterService,private filterService: helperService,
    private cdr:ChangeDetectorRef,private auth:AuthGuard) { }

  ngOnInit(): void {

    if(!this.auth.roles.includes("ADMIN")  && this.auth.roles.includes("TK")  && this.auth.roles.includes("LPC") ){
      this.disableEdit=true;
    }
    this.cols = [
      { field: 'name', header: 'Matter Name' },
      { field: 'orgName', header: 'Organisation Name' },
      { field: 'orgQbId', header: 'QuickBook Id' },
      { field: 'number', header: 'Matter Number' },
      { field: 'statusCode', header: 'Status' },
      { field: 'createDate', header: 'Date Created', data: true, format: `MM/dd/yyyy` },
    ];

    this.statuses = matter.statuses;

    // this.matchModeOptionsString = [
    //   { label: 'Equals', value: FilterMatchMode.EQUALS },
    //   { label: 'Contains', value: FilterMatchMode.CONTAINS },
    // ];

    this.matchModeOptionsDate = matchModeOptionsDateList;

    // this.matchModeOptionsNumber = [
    //   { label: 'Equal', value: FilterMatchMode.EQUALS },
    //   { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
    //   { label: 'Less than', value: FilterMatchMode.LESS_THAN },
    //   { label: 'Between', value: FilterMatchMode.BETWEEN },
    // ];
  }

  loadMatterData(event: LazyLoadEvent) {
    this.lastTableLazyLoadEvent = event;
    event.first = event.first ? event.first : 1;
    event.rows = event.rows ? event.rows : 10;
    let payload=this.filterService.generateEventPayload(event)
    this.loading = true;  
    if(this.matterOrgFilter){payload.filters.push({
			"field" : "orgId",
			"operator" : "equals",
			"value" : this.matterOrgFilter
		 })}
    this.matterService.getAllMatters(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe((res:any) => {
      this.matterData = res.data;
      this.currentPageNumber = res.customValues.currentPageNumber;
      this.totalNumberOfPages = res.customValues.totalNumberOfPages;
      this.totalRecords = res.customValues.totalNumberOfRecords;
      this.loading = false;
    })
  }

  editMatter(view:Boolean,matterObj: any) {
    this.title = "Edit Matter"
    this.matterDialog = true;
    matterObj.isBillable=matterObj.isBillable=='Y'?true:false;
    this.selectedRow=matterObj;
    if(view){
      this.viewOnly=true;
    }
  }

  closeDialog() {
    this.matterDialog = false;
    this.viewOnly = false;
    this.viewMatter=false;
    this.cdr.detectChanges();
    this.loadMatterData(this.lastTableLazyLoadEvent);
  }

  backToMatterGrid(){
    this.viewMatter=false;
    this.loadMatterData(this.lastTableLazyLoadEvent);
  }
  
  clicked(data:any){
    this.selectedRow=data;
    console.log("selectedrow",this.selectedRow)
    this.projectMatterFilter=this.selectedRow.id;
    this.viewMatter=true;
    this.viewMatterName=this.selectedRow.name;
  }

  // generateEventPayload(event:any):any{
  //   console.log("Event",event)
  //   let filters:any[]=[];
  //   let sort:any[]=[];
  //   let payload={
  //     filters:filters,
  //     sort:sort
  //   }
  //   Object.keys(event.filters).forEach(val=>{
  //     if(event.filters[val][0].value!=null){
  //       let obj={
  //         "field" : val,
  //         "operator" : event.filters[val][0].matchMode,
  //         "value" : event.filters[val][0].value
  //       }
  //       payload.filters.push(obj);
  //     }
  //   })
  //   if(event.sortField){
  //     payload.sort=[{
  //       field:event.sortField,
  //       order:event.sortOrder>0?"DESC":"ASC"
  //     }]
      
     

  //   }
    
  //   console.log(payload);
  //   return payload;

  // }


  // get timeIncrement() {
  //   return this.matterForm.get('timeIncrement');
  // }
  // get invType() {
  //   return this.matterForm.get('invType');
  // }
  // get notes() {
  //   return this.matterForm.get('notes');
  // }


}
