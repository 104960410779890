import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { helperService } from 'src/app/utils/helper';
import { AppConfigService } from './app-config.service';


@Component({
  selector: 'app-app-config',
  templateUrl: './app-config.component.html',
  styleUrls: ['./app-config.component.scss']
})
export class AppConfigComponent implements OnInit {
  configurations: any;
  configForm: any;
  cols: any[] = [];
  title: string = '';
  viewOnly: boolean = false;
  configDialog: boolean = false;
  totalRecords: any = 0;
  loading:boolean=false;
  editLoader:boolean=false
  private lastTableLazyLoadEvent: any = null;

  constructor(private helperService: helperService,private appConfigService :AppConfigService,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.configForm = new FormGroup({
      name: new FormControl(''),
      type: new FormControl(''),
      value: new FormControl('', [Validators.required]),
      id:new FormControl('')
    });

    this.cols = [
      { field: 'name', header: 'Configuration Name' },
      { field: 'type', header: 'Type' },
      { field: 'value', header: 'Value' },
      { field: 'updatedOn', header: 'Updated On' },
    ]
  }

  loadConfigData(event: LazyLoadEvent) {
    this.lastTableLazyLoadEvent=event;
    this.loading=true;
    this.appConfigService.getConfigurationsById(null).subscribe(res=>{
      this.configurations=res && res.data?res.data:[];
      this.totalRecords = res && res.data && res.data.length?res.data.length:0;
      this.loading=false;
    })
  }

  viewConfiguration(configuration: any) {
    this.viewOnly=true;
    this.configForm.patchValue(configuration);
    this.configForm.disable();
    this.configDialog = true;
    this.title = 'View Configuration';
  }
  editConfiguration(configuration: any) {
    this.configForm.patchValue(configuration);
    this.configDialog = true;
    this.configForm.enable();
    this.title = 'Edit Configuration';
  }
  closeDialog() {
    this.configDialog = false;
    this.editLoader=false;
    this.viewOnly=false;
    this.loadConfigData(this.lastTableLazyLoadEvent);
  }
  saveConfig(){
    let payload=this.configForm.value;
    if(payload.type.toLowerCase()=="date")
      payload.value=typeof(payload.value)=='string'?payload.value:this.helperService.convertDateToFormattedStr(payload.value);
    this.editLoader=true;
    this.configForm.disable();
    this.appConfigService.updateConfig(payload).subscribe(res=>{
      this.closeDialog();
    })
  }

}
