import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { OrganizationService } from '../organization.service';

@Component({
  selector: 'app-view-organization',
  templateUrl: './view-organization.component.html',
  styleUrls: ['./view-organization.component.scss']
})
export class ViewOrganizationComponent implements OnInit {

  @Input()
  selectedRow: any;

  @Input()
  viewOrg:boolean=false;

  @Input()
  viewOnly: boolean = false;

  @Output() closeFlag=new EventEmitter<boolean>();

  orgForm: any = {};
  btnLoading: Boolean = false;
  reqInvalid: Boolean = false;
  title = "";
  reqName: string = "";
  requestedPersons: any = [];
  submitted: Boolean = false;
  orgDataById?: number;
  orgTypes:any = ['Law Firm','Corporation'];
  invoiceTypes:any =["Consolidated","Matter"];
  selectedStatus: any = '';
  statuses = ['Active','Inactive']
  timeIncrementList:any;

  constructor(private readonly messageService: MessageService, private orgService: OrganizationService) { }

  ngOnInit(): void {
    this.requestedPersons = [];
    this.orgForm = new FormGroup({

      name: new FormControl(''),
      status: new FormControl(''),
      quickbookId: new FormControl(''),
      accountOwner: new FormControl(''),
      organizationType: new FormControl('', [Validators.required]),
      timeIncrement: new FormControl(''),//,[Validators.pattern('^[0-9]+(\.[0-9]{1,2})?$')]
      invoiceType: new FormControl('', [Validators.required]),
      isForceRequestedBy: new FormControl(''),
      notes: new FormControl(''),
      requestedPersons: new FormControl([]),
      reqName: new FormControl(''),
      id:new FormControl('')
    });
    this.editOrg(this.viewOnly, this.selectedRow);
    this.timeIncrementList=[
      {label:"5 Mins",value:"5"},
      {label:"6 Mins",value:"6"},
      {label:"10 Mins",value:"10"},
      {label:"15 Mins",value:"15"},
      {label:"30 Mins",value:"30"},
      {label:"60 Mins",value:"60"},
      {label:"No Round",value:"0"},
    ]
  }
  
  editOrg(viewOnly: boolean, orgData: any) {
    this.orgDataById = orgData
    this.orgForm.patchValue(orgData);
    if (viewOnly) {
      this.title = "View Organization";
      this.viewOnly = true;
      this.orgForm.disable();
    }
    else {
      this.title = "Edit Organization";
      this.orgForm.enable();
    }
    // this.modalService.open(largeDataModal, { size: 'lg', windowClass: 'modal-holder', centered: true });
  }
  onCheckboxChange(event: any) {
    this.orgForm.controls['isForceRequestedBy'].value=event.target.checked;
  }
  saveOrg(){
    if(this.btnLoading)
      return;
    this.submitted = true;
    if (this.orgForm.valid) {
      this.btnLoading = true;
      let payload={
        notes:this.orgForm.controls['notes'].value,
        orgReqPersons:[...this.orgForm.controls['requestedPersons'].value],
        isForceRequestedBy:this.orgForm.controls['isForceRequestedBy'].value?"Yes":"No",
        status:this.orgForm.controls['status'].value,
        accountOwner:this.orgForm.controls['accountOwner'].value,
        organizationType:this.orgForm.controls['organizationType'].value,
        timeIncrement:this.orgForm.controls['timeIncrement'].value,
        invoiceType:this.orgForm.controls['invoiceType'].value
      }
      this.orgForm.disable();
      try {
      this.orgService.updateOrgDataById(payload,this.orgForm.controls['id'].value).subscribe(
          res => {
          this.btnLoading = false;
          if (res.message) {
            this.viewOnly=true;
            this.messageService.add({ severity: 'success', detail: 'Task ' + this.orgForm.controls['name'].value + ' details have been saved successfully' });
          } else {
            this.orgForm.enable();
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        },error => {
          this.btnLoading = false;
          this.orgForm.enable();
          console.log(error)
          this.messageService.add({ severity: 'error', detail:error.message});
        })
      } catch (error:any) {
        this.btnLoading = false;
        this.orgForm.enable();
        console.log(error)
        this.messageService.add({ severity: 'error', detail:error.message});
      }
      
    }
  }
  
  deleteReq(req: any) {
    this.orgForm.controls['requestedPersons'].value.delete(req);
  }

  addReq() {
    if(!this.orgForm.controls['reqName'].value){
      this.reqInvalid=true;
      return;
    }
    this.reqInvalid=false;
    if(this.orgForm.controls['requestedPersons'].value)
      this.orgForm.controls['requestedPersons'].value.add(this.reqName);
    else
      this.orgForm.controls['requestedPersons'].value=new Set([this.reqName]);
    this.reqName = "";
  }
 
  get notes() {
    return this.orgForm.get('notes');
  }
  get name() {
    return this.orgForm.get('name');
  }
  get status() {
    return this.orgForm.get('status');
  }
  get accountOwner() {
    return this.orgForm.get('accountOwner');
  }
  get organizationType() {
    return this.orgForm.get('organizationType');
  }
  get timeIncrement() {
    return this.orgForm.get('timeIncrement');
  }
  get invoiceType() {
    return this.orgForm.get('invoiceType');
  }


  closeDialog(){
    this.closeFlag.emit(true);
  }

}
