import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { TaskCatalogue } from '../dashboards/taskCatalogue.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskCatalogueService } from './task-catalogue.service';
import { descriptionType, practiceArea, rateType, taskTypes } from 'src/app/utils/taskCatalogData';
import { helperService } from 'src/app/utils/helper';
import { taskCatalog } from 'src/app/utils/filterDropDownData';
import { UsersGridService } from '../users-grid/users-grid.service';
// import { stateData } from 'src/app/utils/userData';

@Component({
  selector: 'app-task-catalogue',
  templateUrl: './task-catalogue.component.html',
  styleUrls: ['./task-catalogue.component.scss']
})
export class TaskCatalogueComponent implements OnInit {

  tasks: TaskCatalogue[] = [];
  cols: any[] = [];
  taskCatDialog: boolean = false;
  viewOnly: boolean = false;
  newTask: boolean = false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptions?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  matchModeOptionsNumber?: SelectItem[];
  title: string = "";
  loading: boolean = true;
  totalRecords: number = 0;
  first: number = 1;
  taskForm: any = {};
  descriptionTypes:any=[];
  rateTypes:any=[];
  taskGridTypes:any=[];
  practiceAreaCodes:any=[];
  submitted:boolean=false;
  btnLoading:boolean=false;
  defaultTaskForm:any={};
  private lastTableLazyLoadEvent: any = null;
  defaultTime: any;
  readyStateList:any;
  reqEstimateList:any;
  stateRelatedList:any;
  billableList:any;
  descriptionTypesList:any;
  rateTypesList:any;

  constructor(private userService: UsersGridService,private taskCatService: TaskCatalogueService, private readonly messageService: MessageService ,private cdr:ChangeDetectorRef,private filterService: helperService) { }
  

  ngOnInit(): void {

    this.cols = [
      { field: 'taskName', header: 'Task Name' },
      { field: 'taskFullName', header: 'Task Full Name' },
      { field: 'taskTypeCode', header: 'Task Type Code' },
      { field: 'qbTaskId', header: 'QuickBook ID' },
      { field: 'qbDescription', header: 'Description' },
      { field: 'descriptionTypeCode', header: 'Description Type' },
      { field: 'rate', header: 'Rate' },
      { field: 'rateTypeCode', header: 'Rate Type' },
      { field: 'timeEstimate', header: 'Time Estimate' },
      { field: 'taskClass', header: 'Class' },
      { field: 'billable', header: 'Billable' },
      // { field: 'consolidateTimeRec', header: 'Consolidation Time Record' },
      { field: 'reqEstimate', header: 'Request Estimate' },
      { field: 'practiceAreaCode', header: 'Practice Area Code' },
      { field: 'stateRelated', header: 'State Related' },
      // { field: 'approvalReq', header: 'Approval Request' },
      { field: 'readyStatus', header: 'Ready Status' }
    ];

    this.matchModeOptionsNumber = [
      { label: 'Equal', value: FilterMatchMode.EQUALS },
      { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
      { label: 'Less than', value: FilterMatchMode.LESS_THAN },
      { label: 'Between', value: FilterMatchMode.BETWEEN },
    ];
    
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
    this.readyStateList=taskCatalog.readyStateList;
    this.reqEstimateList=taskCatalog.reqEstimateList;
    this.stateRelatedList=taskCatalog.stateRelatedList;
    this.billableList=taskCatalog.billableList;
    this.descriptionTypesList=taskCatalog.descriptionTypesList;
    this.rateTypesList=taskCatalog.rateTypesList;
    
    this.taskForm = new FormGroup({
      qbTaskId:new FormControl(''),
      id:new FormControl(''),
      taskName: new FormControl(''),
      taskTypeCode: new FormControl('', [Validators.required]),
      qbDescription: new FormControl(''),
      descriptionTypeCode: new FormControl('', [Validators.required]),
      rate: new FormControl('', [Validators.required]),
      rateTypeCode: new FormControl('', [Validators.required]),
      timeRangeMin: new FormControl('', [Validators.required]),
      timeRangeMax: new FormControl('', [Validators.required]),
      enforceMax: new FormControl('', [Validators.required]),
      timeEstimate: new FormControl('', [Validators.required]),
      // stateCode: new FormControl('', [Validators.required]),
      taskClass: new FormControl(''),
      billable: new FormControl('', [Validators.required]),
      // consolidateTimeRec: new FormControl('', [Validators.required]),
      reqEstimate: new FormControl(''),
      practiceAreaCode: new FormControl('', [Validators.required]),
      stateRelated: new FormControl('', [Validators.required]),
      countryRelated: new FormControl('', [Validators.required]),
      quantityRelated: new FormControl(''),
      quantityType: new FormControl(''),
      projectOnly: new FormControl(''),
      // approvalReq: new FormControl('', [Validators.required]),
      readyStatus:new FormControl('')
    });

    this.taskForm.get('quantityRelated').valueChanges.subscribe((isqtyRel:any) => {
      const qTcontrol = this.taskForm.get('quantityType');

      if (isqtyRel === 'Yes') {
        qTcontrol.setValidators([Validators.required]);
      } else {
        qTcontrol.clearValidators();
      }

      qTcontrol.updateValueAndValidity();
    });

    this.defaultTaskForm=this.taskForm.value;
    this.descriptionTypes =descriptionType;
    this.rateTypes =rateType;
    this.taskGridTypes = taskTypes;
    // this.practiceAreaCodes=practiceArea;
    this.getTkPracticeArea();
  }

  getTkPracticeArea() {
    this.userService.getPracticeArea().subscribe(res => {
      console.log("res", res)
      this.practiceAreaCodes = res.data;
    })
    // return areas;
  }
  loadTaskData(event: LazyLoadEvent) {
    this.lastTableLazyLoadEvent = event;
    this.loading = true;
    event.first = event.first ? event.first : 1;
    event.rows = event.rows ? event.rows : 10;
    let payload=this.filterService.generateEventPayload(event)

    this.taskCatService.getCatList(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe(res => {
      let list = res.data;
      list.forEach((obj: any) => {
        obj['timeEstimate']=this.filterService.convertTimeNumToStr(obj.timeEstimate);
      })
      this.tasks=list
      this.loading = false;
      this.totalRecords = res.customValues.totalNumberOfRecords;
    })
  }
  addTask(): void {
    this.taskCatDialog = true;
    this.viewOnly = false;
    this.newTask = true;
    this.title = "Add Task Catalog";
  }

  closeDialog(): void {
    this.taskCatDialog = false;
    this.submitted = false;
    this.taskForm.reset(this.defaultTaskForm);
    this.taskForm.enable();
    this.loadTaskData(this.lastTableLazyLoadEvent);
    this.cdr.detectChanges();
  }

  editTask(task: any): void {
    task.timeEstimate=this.filterService.convertTimeToDate(this.filterService.convertStrToNum(task.timeEstimate));
    task.timeRangeMin=this.filterService.convertTimeToDate(task.timeRangeMin);
    task.timeRangeMax=this.filterService.convertTimeToDate(task.timeRangeMax);
    this.taskForm.patchValue(task);
    if(task.qbTaskId!=null&&task.qbTaskId!=''){
      this.taskForm.controls['projectOnly'].disable()
      this.taskForm.controls['projectOnly'].value="No";
    }else{
      this.taskForm.controls['projectOnly'].value="Yes";
    }
    this.taskCatDialog = true;
    this.viewOnly = false;
    this.newTask = false;
    this.title = "Edit Task Catalog";
    this.onRateTypeChange()
    // this.onEnforceMaxChange()
  }
  viewTask(task: any): void {
    task.timeEstimate=this.filterService.convertTimeToDate(this.filterService.convertStrToNum(task.timeEstimate));
    task.timeRangeMin=this.filterService.convertTimeToDate(task.timeRangeMin);
    task.timeRangeMax=this.filterService.convertTimeToDate(task.timeRangeMax);
    this.taskForm.patchValue(task);
    this.taskForm.disable();
    this.taskCatDialog = true;
    this.viewOnly = true;
    this.newTask = false;
    this.title = "View Task Catalog";
  }
  saveTask(){
    if(this.btnLoading)
      return;
    this.submitted = true;
    if (this.taskForm.valid) {
      this.btnLoading = true;
      this.taskForm.disable();
      try {
        let x=this.taskForm.getRawValue()
        x.timeEstimate=this.filterService.convertDateToTime(x.timeEstimate);
        x.timeRangeMin=this.filterService.convertDateToTime(x.timeRangeMin);
        x.timeRangeMax=this.filterService.convertDateToTime(x.timeRangeMax);
        this.taskCatService.saveCatalog(x,this.taskForm.controls['id'].value).subscribe(
          res => {
          this.btnLoading = false;
          if (res.message) {
            this.viewOnly=true;
            this.messageService.add({ severity: 'success', detail: 'Task ' + this.taskForm.controls['taskName'].value + ' details have been saved successfully' });
          } else {
            this.taskForm.enable();
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        },error => {
          this.btnLoading = false;
          this.taskForm.enable();
          console.log(error)
          this.messageService.add({ severity: 'error', detail:error.message});
        })
      } catch (error:any) {
        this.btnLoading = false;
        this.taskForm.enable();
        console.log(error)
        this.messageService.add({ severity: 'error', detail:error.message});
      }
      
    }
  }

  onRateTypeChange(){
    if(this.taskForm.controls['rateTypeCode'].value=="Fixed Rate"){
      this.taskForm.controls['enforceMax'].enable();
      this.taskForm.controls['enforceMax'].setValidators([Validators.required]);
      this.taskForm.controls['enforceMax'].updateValueAndValidity();
      this.onEnforceMaxChange()
    }else if(this.taskForm.controls['rateTypeCode'].value=="Hourly"){
      this.taskForm.controls['enforceMax'].disable();
      this.taskForm.controls['enforceMax'].clearValidators();
      this.taskForm.controls['enforceMax'].updateValueAndValidity();

      this.taskForm.controls['timeRangeMax'].disable();
      this.taskForm.controls['timeRangeMax'].clearValidators();
      this.taskForm.controls['timeRangeMax'].updateValueAndValidity();
    }
  }

  onEnforceMaxChange(){
    if(this.taskForm.controls['enforceMax'].value=="Yes"){
      this.taskForm.controls['timeRangeMax'].enable();
      this.taskForm.controls['timeRangeMax'].setValidators([Validators.required]);
      this.taskForm.controls['timeRangeMax'].updateValueAndValidity();
    }else{
      this.taskForm.controls['timeRangeMax'].clearValidators();
      this.taskForm.controls['timeRangeMax'].updateValueAndValidity();
    }
  }


  get taskName() {
    return this.taskForm.get('taskName');
  }

  get taskTypeCode() {
    return this.taskForm.get('taskTypeCode');
  }

  get description() {
    return this.taskForm.get('qbDescription');
  }

  get descriptionTypeCode() {
    return this.taskForm.get('descriptionTypeCode');
  }

  get rate() {
    return this.taskForm.get('rate');
  }

  get rateTypeCode() {
    return this.taskForm.get('rateTypeCode');
  }

  get timeRangeMin() {
    return this.taskForm.get('timeRangeMin');
  }

  get timeRangeMax() {
    return this.taskForm.get('timeRangeMax');
  }

  get enforceMax() {
    return this.taskForm.get('enforceMax');
  }

  get timeEstimate() {
    return this.taskForm.get('timeEstimate');
  }

  // get stateCode() {
  //   return this.taskForm.get('stateCode');
  // }

  get taskClass() {
    return this.taskForm.get('taskClass');
  }

  get billable() {
    return this.taskForm.get('billable');
  }

  // get consolidateTimeRec() {
  //   return this.taskForm.get('consolidateTimeRec');
  // }

  get reqEstimate() {
    return this.taskForm.get('reqEstimate');
  }

  get practiceAreaCode() {
    return this.taskForm.get('practiceAreaCode');
  }

  get stateRelated() {
    return this.taskForm.get('stateRelated');
  }

  get countryRelated() {
    return this.taskForm.get('countryRelated');
  }

  get quantityRelated() {
    return this.taskForm.get('quantityRelated');
  }

  get quantityType() {
    return this.taskForm.get('quantityType');
  }

  get projectOnly() {
    return this.taskForm.get('projectOnly');
  }

  // get approvalReq() {
  //   return this.taskForm.get('approvalReq');
  // }
  get readyStatus() {
    return this.taskForm.get('readyStatus');
  }
}
