import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-alert',
  templateUrl: './confirmation-alert.component.html',
  styleUrls: ['./confirmation-alert.component.scss']
})
export class ConfirmationAlertComponent implements OnInit {

  @Input() 
  message?: string;

  @Input() 
  showAlert?: Boolean;

  
  @Output() 
  response: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
   
  }

  accept(){
    this.response.emit('Yes')
  }
  reject(){
    this.response.emit('No')
  }

}
