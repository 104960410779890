import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minutesToTime'
})
export class MinutesToTimePipe implements PipeTransform {
  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutesLeft).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  }
}