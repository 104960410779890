import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TimeRecordExportService } from './time-record-export.service';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import Swal from 'sweetalert2';
import { helperService } from 'src/app/utils/helper';
import { matchModeOptionsDateList } from 'src/app/utils/filterDropDownData';

@Component({
  selector: 'app-time-record-export',
  templateUrl: './time-record-export.component.html',
  styleUrls: ['./time-record-export.component.scss']
})
export class TimeRecordExportComponent implements OnInit {

  
  loading: boolean = false;
  totalRecords: number = 0;
  first: number = 1;
  cols:any[]=[];
  previewCols:any[]=[];
  gridCustomMessage:any=''
  previewData:any[]=[];
  timeRecordJobs:any[]=[];
  showTrDialog:Boolean=false;
  trForm:any=''
  title:any=''
  lazyloadEvent: any;
  matchModeOptionsDate: SelectItem[]=matchModeOptionsDateList;

  constructor(private timeRecordExportService:TimeRecordExportService,private readonly messageService: MessageService,private helperService: helperService) {
    
    this.cols = [
      { field: 'id' },
      { field: 'createdDate'},
      { field: 'description' },
      { field: 'createdBy' },
      { field: 'recordsCount' },
    ];
    this.previewCols = [
      { field: 'dateCreated' },
      { field: 'orgName'},
      { field: 'matterName' },
      { field: 'projectName' },
      { field: 'taskName' },
      { field: 'timeRecDesc' },
      { field: 'expTrDuration' },
      { field: 'billingStatus' }
    ];
    this.trForm = new FormGroup({
      id: new FormControl(''),
      taskName: new FormControl(''),
      projectName: new FormControl(''),
      organizationName: new FormControl(''),
      matterName: new FormControl(''),
      employeeName: new FormControl(''),
      timeEstimate: new FormControl(''),
      billingStatus: new FormControl(''),
      billRate: new FormControl(''),
      payRate: new FormControl(''),
      notes: new FormControl(''),
    });

   }

  ngOnInit(): void {
  }
  generateData(){
    this.loading=true;
    this.timeRecordExportService.generateExportData().subscribe(res=>{
      if(res===null){
        this.loading=false;
        Swal.fire("No records to Export.");
      }
      if(res.data.statusCode==200){
        this.loading=false;
          this.loadExportGrid(this.lazyloadEvent);
      }

    })
  }
  preview(trData:any){
    this.title="Preview Data";
    this.previewData=trData["batchDetails"];
    this.trForm.patchValue(trData["batchDetails"]);
    this.trForm.disable();
    this.showTrDialog=true;
  
  }

  loadExportGrid(event:LazyLoadEvent){
    this.gridCustomMessage=''
    this.loading=true;
    this.lazyloadEvent=event;
    this.timeRecordJobs=[]
    let payload=this.helperService.generateEventPayload(event);
    this.timeRecordExportService.getAllBatchList(payload).subscribe((res) =>{
          this.totalRecords=res.data?res.data.length:0; 
          this.timeRecordJobs=res.data;
          this.loading=false;
      }, res => {
        this.loading = false;
        this.gridCustomMessage=res.error.message?res.error.message:'';
      });
  }
  download( trData:any){
    console.log(trData)
    this.timeRecordExportService.downloadBatch(trData.id).subscribe(res =>{
      const responseBody = res.body;
      if(res.headers.get('Content-Type')=="application/octet-stream"&& responseBody instanceof ArrayBuffer){
        const contentDisposition:any = res.headers.get('Content-Disposition');
        let fn;
        const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        if (filenameMatch && filenameMatch[1]) {
          fn= filenameMatch[1].replace(/['"]/g, '');
        }
        this.saveFile(responseBody,fn);
      }
      else if (responseBody !== null){
        Swal.fire("Nothing to Download.");
      }
    },
    (error) => {
      console.error('Error fetching data', error);
    })
  }

  closeDialog(){
    this.loadExportGrid(this.lazyloadEvent);
  }

  saveFile(data: ArrayBuffer,filename:any) {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const downloadLink = document.createElement('a');
    
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename; 
    downloadLink.click();
  
    URL.revokeObjectURL(downloadLink.href); 
  }
  
}
