import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { TaskDashboard } from '../dashboards/task-grid.model';
import { TaskGridService } from './task-grid.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsGridService } from '../projects-grid/projects-grid.service';
import { DatePipe } from '@angular/common';
import { AuthGuard } from 'src/app/account/auth/app.gaurd';
import Swal from 'sweetalert2';
import { helperService } from 'src/app/utils/helper';
import { TimeRecord, TimeRecordTableElement } from '../dashboards/timeRecord.model';
import { UsersGridService } from '../users-grid/users-grid.service';
import { matchModeOptionsDateList, task } from 'src/app/utils/filterDropDownData';
import { Table } from 'primeng/table';
import { AppConfigService } from '../app-config/app-config.service';
import { TaskCatalogueService } from '../task-catalogue/task-catalogue.service';

@Component({
  selector: 'app-task-grid',
  templateUrl: './task-grid.component.html',
  styleUrls: ['./task-grid.component.scss']
})
export class TaskGridComponent implements OnInit {
  @Input()
  dashboard: any = false;

  @Input()
  tkdashboard: any = false;

  @Input()
  lpcdashboard: any = false;

  @Input()
  projTasksFilter: any = false;

  filterDates:any={taskDueDate:null,createdOn:null,completedDate:null}
  filterDatesMatchModes:any={taskDueDate:'dateIs',createdOn:'dateIs',completedDate:'dateIs'}

  userTkPracticeAreas: any;
  public tasks: TaskDashboard[] = [];
  public cols: any = [];
  public tkUserCols: any = [];
  colsView: any = {
    showForApproval: false,
    showDeleteTask: false,
    showCloneTask: false
  };
  statuses: any[] = [];
  priorities: any[] = [];
  descriptionTypes: any[] = [];
  taskGridTypes: any[] = [];
  rejectedReason: string = "";
  status = ['ON_HOLD', 'COMPLETED', 'IN_PROGRESS', 'REGECTED'];
  priority = ['Low', 'High', 'Medium'];
  taskTypes = ['Contract', 'Trade Mark', 'Administrative', 'TradeMark', 'Corporate'];
  descriptionType = ['Free', 'Auto'];
  rateType = ['Fixed Rate', 'Hourly'];
  practiceArea = ['Contract Management Services', 'Corporate Services', 'Director Services', 'Family Law', 'Finance Services', 'Fund Services', 'Immigration Services', 'Intellectual Property', 'International Services', 'KYC/AML', 'Labor & Employment', 'Legal Project Services', 'Legal Research', 'Litigation', 'Treasury Services', 'Trusts & Estates', 'Bankruptcy'];
  frozenCols?: any[];
  loadingTkUsers: boolean = true;
  loadingDayWiseData: boolean = true;
  loading: boolean = true;
  gridErrorMessage: any = "";
  onlylpcadmin: boolean = false;
  onlytk: boolean = false;
  tkRatingWarning: boolean = false;
  combroles: boolean = false;
  forApprovalDialog: boolean = false;
  btnLoading: boolean = false;
  disableDelbtnFlag: boolean = true;
  disableReassignbtnFlag: boolean = true;
  totalRecords: number = 0;
  first: number = 1;
  loadingData: boolean = false;
  dayoptions: any;
  deleteTRAR: boolean = false;
  deleteConfirmationDialog: boolean = false;
  faloading: boolean = false;
  FAdisableFlag: boolean = false;
  matchModeOptionsString?: SelectItem[];
  matchModeOptionsDate: SelectItem[] =[
    { label: 'Date Is', value: 'dateIs' },
    { label: 'Date Before', value: 'ltDate' },
    { label: 'Date After', value: 'gtDate' },
    { label: 'Date Range', value: 'betweenDate' },
  ];
  matchModeOptionsNumber?: SelectItem[];
  viewOnly: boolean = false;
  submitted: boolean = false;
  disableADDTR: boolean = false;
  title: string = "";
  rateTypes: any[] = [];
  taskDialog = false;
  states: any = [];
  countries: any = [];
  taskForm: any = [];
  lpcUsersList = [];
  tkUsersList = [];
  loadingTRData: boolean = false;
  taskAssignDialog: boolean = false;
  viewTaskAssignmentDialog: boolean = false;
  acceptTaskDialog: boolean = false;
  addTRDialog: boolean = false;
  TKAssignedUser: string = "";
  assignedInvalid: boolean = false;
  newTaskDialog: boolean = false;
  maxTimewarning: boolean = false;
  allProjects = []
  AllTasks = []
  newTask: any = "";
  filterOptions = task.filters;
  selectedFilterOption: string = 'All Tasks';
  stateCodeMapList: any;
  canadaStateList:any;
  enableReassignTask: boolean = false;
  rejectTimeRecords: boolean = false;
  projectAssignedTasks = [];
  today = new Date();
  selectedProject: any = "";
  projectTaskCols: any = [];
  openedtask: any;
  assignBtnLoading: boolean = false;
  showStateCode: boolean = true;
  showCountry: boolean = true;
  dueDateError: boolean = false;
  enableDeleteTask: boolean = false;
  showQuantityState: boolean = false;
  private lastTableLazyLoadEvent: any = null;
  dueDaySlots: any = []
  tkUsers: any = []
  deleteReason: string = '';
  reassignReason: string = '';
  taskAssignedUsers: any = []
  tkRatingMand: boolean = false;
  deleteReasonList = task.deleteReasonList
  reassignReasonList = task.reassignReasonList
  reviewstatuslist = task.reviewstatuslist
  timeSlot = [
    { "label": "30 mins", value: 30 },
    { "label": "1 hour", value: 60 },
    { "label": "1 hour 30 mins ", value: 90 },
    { "label": "2 hours ", value: 120 },
    { "label": "2 hours 30 mins ", value: 150 },
    { "label": "3 hours ", value: 180 },
    { "label": "3 hours 30 mins ", value: 210 },
    { "label": "4 hours ", value: 240 },
    { "label": "4 hours 30 mins ", value: 270 },
    { "label": "5 hours ", value: 300 },
    { "label": "5 hours 30 mins ", value: 330 },
    { "label": "6 hours  ", value: 360 },
    { "label": "6 hours 30 mins ", value: 390 },
    { "label": "7 hours  ", value: 420 },
    { "label": "7 hours 30 mins ", value: 450 },
    { "label": "8 hours  ", value: 480 },
    { "label": "10 hours  ", value: 600 },
    { "label": "18 hours  ", value: 1080 },
    { "label": "24 hours  ", value: 1440 },
    { "label": "48 hours  ", value: 2880 },
  ]
  timeRecords: any[] = []
  openTask: any;
  userCountryInformation: any = []
  timeRecordsDueDate: any;
  timeRecordsStartDate: any;
  acceptDdata: any;
  daywiseData: any;
  userrole: any;
  viewflag: boolean = true;
  includeToday: Boolean = false;
  rejbtnLoading: Boolean = false;
  accbtnLoading: Boolean = false;
  rejbtnflag: Boolean = true;
  accbtnflag: Boolean = true;
  accepteddaywiseData: any;
  rejectresponse: any = [];
  assignedTime: any;
  trForm: any = [];
  dropdownDates: any = [];
  dropdownTime: any = [];
  defaultTime: any;
  radioStatus: string = "";
  acceptedDates: any;
  loggedInId: string = "";
  assignedTkUserName: string = "";
  assignmentStatuses: any;
  approvalRequiredList: any;
  isRejectedFilter:any;
  assignedTkUser: any;
  confirmMessage: string = "";
  showConfirmAlert: Boolean = false;
  confirmMsgCategory: string = ""
  showProjectsView: Boolean = false;
  viewTaskName: string = "";
  selectedTask:any=null;
  searchTerm:any="";
  catalogueRecord:any=null;
  lastSearchTerm:any='';
  dateFilters:any=[];
  constructor(
    private datePipe: DatePipe,
    private taskService: TaskGridService,
    private projectService: ProjectsGridService,
    private readonly messageService: MessageService,
    private cdr: ChangeDetectorRef,
    public auth: AuthGuard,
    private filterService: helperService,
    private userservice: UsersGridService,
    private taskCatalogue:TaskCatalogueService,
    private appConfigService: AppConfigService) {

  }

  ngOnInit(): void {
    if (this.tkdashboard || this.lpcdashboard)
      this.dashboard = true;
    console.log("inputs", this.tkdashboard, this.lpcdashboard)
    this.userrole = this.auth.roles;
    this.getLoginUserMail();
    this.controlactions();
    // this.states = stateData;
    this.stateCodeMapList = task.stateCodeMapList;
    this.canadaStateList = task.canadaStateList;
    this.defaultTime = new Date();
    this.defaultTime.setHours(0, 0, 0, 0);
    this.countries = task.countryList;
    if(this.projTasksFilter)
      this.showProjectsView=false;
    this.getTkPracticeArea();
    console.log("usertkpractice area", this.userTkPracticeAreas)
    this.cols = [
      // { field: 'projectName', header: 'Project Name' },
      { field: 'taskName', header: 'Task Name' },
      { field: 'id', header: 'Task ID' },
      { field: 'description', header: 'Description' },
      { field: 'stateCode', header: 'State' },
      { field: 'statusCode', header: 'Status' },
      { field: 'isCompleted', header: 'Task Completed' },
      { field: 'projTaskAssignmentStatus', header: 'Task Assignment Status' },
      { field: 'isRejected', header: 'isRejected' },
      { field: 'reviewStatus', header: 'Review Status' },
      { field: 'taskDueDate', header: 'Due Date' },
      { field: 'progress', header: 'Task Progress' },
      { field: 'createdOn', header: 'Created On' },
      { field: 'createdBy', header: 'Created By' },
      { field: 'completedDate', header: 'Completed On' },
      { field: 'projTaskAssignmentUsername', header: 'Assigned To' },
      { field: 'dueDaySlot', header: 'Due Day Slot' },
      { field: 'taskTypeCode', header: 'Project Type' },
      { field: 'practiceAreaCode', header: 'Practice Area' },
      { field: 'rateTypeCode', header: 'Rate Type' },
      { field: 'descriptionTypeCode', header: 'Description Type' },
      { field: 'isApprovalRequired', header: 'Approval Required' },
      { field: 'orgName', header: 'Organization Name' },
      { field: 'matterName', header: 'Matters Name' }


      // { field: 'qbTaskId', header: 'QuickBook Id' },
    ];

    this.projectTaskCols = [
      { field: 'taskName', header: 'Task Name' },
      { field: 'taskTypeCode', header: 'Project Type' },
      { field: 'description', header: 'Description' }
    ];

    this.taskForm = new FormGroup({
      id: new FormControl(''),
      taskName: new FormControl(''),
      projectName: new FormControl(''),
      orgName: new FormControl(''),
      matterName: new FormControl(''),
      qbTaskId: new FormControl(''),
      description: new FormControl(''),
      descriptionTypeCode: new FormControl(''),
      timeEstimate: new FormControl('', [Validators.required]),
      actualTime: new FormControl(''),
      isEnforceMaximum: new FormControl(''),
      stateCode: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      isCompleted: new FormControl('', [Validators.required]),
      projTaskAssignmentStatus: new FormControl(''),
      isBillable: new FormControl('', [Validators.required]),
      consolidatedTimeRecords: new FormControl('', [Validators.required]),
      isRequireEstimate: new FormControl('', [Validators.required]),
      statusCode: new FormControl('', [Validators.required]),
      isApprovalRequired: new FormControl('', [Validators.required]),
      taskDueDate: new FormControl('', [Validators.required]),
      projectDueDate: new FormControl(''),
      openEnded: new FormControl('', [Validators.required]),
      quantityState: new FormControl([]),
      quantityNumber: new FormControl(''),
      maxTime: new FormControl(''),
      rateTypeCode: new FormControl(''),
      isStateRelated: new FormControl(''),
      stateDesc: new FormControl(''),
      // stateCode:new FormControl(''),
      countryRelated: new FormControl(''),
      createdOn: new FormControl(''),
      completedDate: new FormControl(''),
      createdBy: new FormControl(''),
      dueDaySlot: new FormControl(''),
      tkRating: new FormControl(''),
      quantityType: new FormControl(''),
      holdTimeUntilComp: new FormControl('', [Validators.required]),
      approver: new FormControl('', [Validators.required]),
      approved: new FormControl(''),
      timeRecordsLoggedTime: new FormControl(''),
      includeToday: new FormControl(''),
      projTaskAssignmentTkId: new FormControl(''),
      projTaskAssignmentUsername: new FormControl(''),
      quantityRelated: new FormControl(''),
      progress: new FormControl(''),
      reviewStatus: new FormControl(''),
      practiceAreaCode: new FormControl(''),
      notes: new FormControl(''),
      driveLink: new FormControl(''),
      catEnforceMax: new FormControl(''),
      isRejected:new FormControl('')
    });

    // this.taskForm.get('isEnforceMaximum').valueChanges.subscribe((isEnfMax: any) => {
    //   if (isEnfMax === 'Yes' && this.taskForm.controls['openEnded'].value!="Yes") {
    //     this.taskForm.get('maxTime').setValidators([Validators.required]);
    //   } else {
    //     this.clearFormValidation('maxTime')
    //   }
    // });

    // this.taskForm.get('openEnded').valueChanges.subscribe((isEnfMax: any) => {
    //   if (isEnfMax === 'Yes' ) {
    //     this.clearFormValidation('maxTime')
       
    //   } else if(this.taskForm.controls['isEnforceMaximum'].value=="Yes"){
    //     this.taskForm.get('maxTime').setValidators([Validators.required]);
    //     this.taskForm.get('maxTime').updateValueAndValidity();
    //   }
    // });

    this.taskForm.get('consolidatedTimeRecords').valueChanges.subscribe((value: any) => {
      if (value == 'Yes') {
        this.taskForm.patchValue({ holdTimeUntilComp: 'Yes' });
        this.taskForm.controls['holdTimeUntilComp'].disable();
      } else {
        this.taskForm.patchValue({ holdTimeUntilComp: this.taskForm.controls['holdTimeUntilComp'].value ? this.taskForm.controls['holdTimeUntilComp'].value : null });
        this.taskForm.controls['holdTimeUntilComp'].enable();
      }
    });

    this.trForm = new FormGroup({
      date: new FormControl(''),
      description: new FormControl(''),
      enteredTime: new FormControl(''),
      taskCompleted: new FormControl(''),
    })

    this.statuses = task.statuses;
    this.assignmentStatuses = task.assignmentStatuses;
    this.descriptionTypes = task.descriptionTypes;
    this.rateTypes = task.rateTypes;
    this.dueDaySlots = task.dueDaySlots;
    this.approvalRequiredList = task.approvalRequiredList;
    this.isRejectedFilter=task.isRejectedFilter;


    this.projectService.getLPCUsersList({
      "roles": ["LPC", "ADMIN", "Approver"],
      // "userTypes": ["Internal"]
    }).subscribe(res => {
      this.lpcUsersList = res.data;
    })

    this.projectService.getLPCUsersList({
      "roles": ["TK"]
    }).subscribe(res => {
      this.tkUsersList = res.data;
    })
    this.loadAllProjects()
    this.loadAllTasks()
    if (this.lpcdashboard || (!this.tkdashboard && !this.onlytk)) {
      this.colsView.showForApproval = true;
      this.colsView.showDeleteTask = true;
    }
    if(this.userrole.includes('LPC') || this.userrole.includes('ADMIN')){
      this.colsView.showCloneTask  = true;
    }
  }


  loadAllTasks() {
    this.taskService.getAllProjectTaskFullNameList().subscribe((res: any) => {

      for (let k = 0; k < res.data.length; k++) {
        let str = "";
        let taskFullName = res.data[k].taskFullName ? res.data[k].taskFullName : "";
        for (let j = 1; j < taskFullName.split(":").length; j++) {
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
          str = str + String.fromCharCode(160);
        }
        res.data[k].taskName = str + res.data[k].taskName;
      }
      this.AllTasks = res.data;

    })
  }

  loadAllProjects() {
    this.projectService.getProjectsList(null, null, false, {}).subscribe(res => {
      this.allProjects = res.data;
      this.projectAssignedTasks = [];
    })
  }
  consolidatedTimeRecordsChange(value: any, holdTimeUntilComp?: any) {
    if (value == 'Yes') {
      this.taskForm.patchValue({ holdTimeUntilComp: 'Yes' });
      this.taskForm.controls['holdTimeUntilComp'].disable();
    } else {
      this.taskForm.patchValue({ holdTimeUntilComp: holdTimeUntilComp ? holdTimeUntilComp : null });
      this.taskForm.controls['holdTimeUntilComp'].enable();
    }
  }

  loadTasksData(event: LazyLoadEvent, table?: Table) {
    this.lastTableLazyLoadEvent = event;
    this.loading = true;
    if (table) {
      table._first = 0;
      event.first = 1;
      event.rows = 10;
    } else {
      event.first = event.first ? event.first : 1;
      event.rows = event.rows ? event.rows : 10;
    }
    
    let payload = this.filterService.generateEventPayload(event);
    this.tasks = []

    if(this.projTasksFilter){
        payload.filters.push({
          "field": "projectName",
          "operator": "equals",
          "value": this.projTasksFilter
        })
    }
    if(this.dateFilters){
      payload.filters=payload.filters.concat(this.dateFilters)
    }

    // payload.filters.push({"field":"taskStatus","operator":"notEquals","value":"OPEN"})
    if (this.selectedFilterOption == 'My Tasks') {
      payload["category"] = "My Tasks";
      payload.filters.push(
        {
          "field": "createdBy",
          "operator": "equals",
          "value": this.auth.jwtData.email,
          "logicGrp": "OR"
        }
      );
      payload.filters.push(
        {
          "field": "projTaskAssignmentUsername",
          "operator": "equals",
          "value": this.auth.jwtData.email,
          "logicGrp": "OR"
        }
      );
      payload.filters.push(
        {
          "field": "approver",
          "operator": "equals",
          "value": this.loggedInId,
          "logicGrp": "OR"
        }
      );
    }
    else {
      payload["category"] = "All Tasks";
    }
    if(this.lastSearchTerm!=this.searchTerm){
      event.first =1
    }
    this.lastSearchTerm=this.searchTerm;
    payload["searchTerm"]=this.searchTerm;
    this.gridErrorMessage = ""
    this.taskService.getProjectTaskList(Math.floor(event.first / event.rows + 1), event.rows, this.filterService.useDashboardUrl(this.userrole, this.tkdashboard), payload).subscribe(res => {
      this.tasks = res.data;
      this.tasks.map((obj: any) => {
        obj['actualTime']=this.filterService.convertTimeNumToStr(obj['actualTime']);
        obj["disableAssignTask"] = false;
        obj["showAssignTask"] = false;

        obj["disableEditTask"] = false;

        obj["showAddTRTask"] = false;
        obj["disableAddTRTask"] = false;

        obj["showAcceptRejectTask"] = false;

        obj["disableForApproval"] = false;
        obj["disableDeleteTask"] = false;

        obj["disableCloneTask"] = true;

        if ((this.filterService.isPastDate(new Date(obj["taskDueDate"])) && obj.statusCode != 'Assigned') || (obj.approved == 'No')) {
          obj["disableAssignTask"] = true;
        }

        if(this.userrole.includes('LPC') || this.userrole.includes('ADMIN')){
          obj["disableCloneTask"] = false;
        }

        if ((this.tkdashboard || !this.userrole.includes('ADMIN')) && (obj.statusCode == 'Approved' || obj.statusCode == 'Assigned')) {
          obj["disableEditTask"] = true;
        }
        if (this.onlytk || this.tkdashboard) {
          obj["showAcceptRejectTask"] = true;
        }
        if (this.onlytk || this.tkdashboard) {
          obj['disableAssignTask'] = true;
          obj["showAddTRTask"] = true;
        }
        if (obj.statusCode != 'Accepted' && obj.statusCode != 'COMPLETED' && obj.statuscode != 'Rejected') {
          obj["disableAddTRTask"] = true;
        }
       
        if (this.forApprovalDisplayFlag(obj)) {
          obj['disableForApproval'] = true;
        }
        if (this.lpcdashboard || (!this.tkdashboard && !this.onlytk)) {
          obj['showAssignTask'] = true;
        }
        if (obj.statusCode == 'Approved') {
          obj['disableAssignTask'] = true;
        }
        if (this.lpcdashboard || (!this.tkdashboard && !this.onlytk)) {
          
          // obj["disableCloneTask"] = false;
        }
        if (obj.statusCode == 'Approved') {
          obj['disableDeleteTask'] = true;
        }
        if (obj.projectClosedDate && new Date(obj.projectClosedDate)) {
          const enableAction = this.filterService.isPastDate(new Date(obj.projectClosedDate))
          if (enableAction) {
            obj['disableEditTask'] = true;
            obj['disableDeleteTask'] = true;
            obj['disableAssignTask'] = true;
            obj['disableAddTRTask'] = true;
            obj['disableForApproval'] = true;
            obj['disableCloneTask'] = true;
          }
        }

      })
      this.loading = false;
      this.totalRecords = res.customValues.totalNumberOfRecords;
    }, res => {
      this.loading = false;
      this.tasks = []
      this.gridErrorMessage = res?.error?.message && res?.error?.status!=500 ? res.error.message : "Error while fetching Data..!";
    })
  }
  getTkPracticeArea() {
    this.userservice.getPracticeArea().subscribe(res => {
      console.log("res", res)
      this.userTkPracticeAreas = res.data;
    })
    // return areas;
  }

  cloneTask(task:any){
    this.loading=true;
    this.taskService.cloneTask(task.id).subscribe(res => {
      this.loading=false;
      this.editTask(res.data);
      this.loadTasksData(this.lastTableLazyLoadEvent);
    })
  }

  viewTask(task: any) {
    this.taskDialog = true;
    this.viewOnly = true;
    task.taskDueDate = this.datePipe.transform(task.taskDueDate, "MM/dd/yyyy");
    if (task.quantityType == "State") {
      this.showQuantityState = true;
      task.quantityState = task.quantityState ? task.quantityState.map((obj: any) => obj.stateCode) : [];
    } else {
      this.showQuantityState = false;
    }

    if(task.countryRelated == "Yes"){
      task.country = task.country ?task.country :"United States";
    }
    // task.driveLink="aerfnaeurbairbpaierbfaebfaehbaeibaernaej";
    this.taskForm.patchValue(task);
    this.taskForm.controls['timeEstimate'].value = this.filterService.convertTimeToDate(task.timeEstimate);
    this.taskForm.controls['maxTime'].value = this.filterService.convertTimeToDate(task.maxTime);
    this.taskForm.disable();
    if (task.isStateRelated != "Yes") {
      this.taskForm.controls['stateCode'].disable()
      this.showStateCode = false;
    }
    if (task.countryRelated != "Yes") {
      this.taskForm.controls['country'].disable()
      this.showCountry = false;
    }
    this.title = "View Task";

  }

  controlactions() {
    if (this.filterService.isOnlyTKUser(this.userrole)) {
      this.onlytk = true;
      return;
    }
    if (this.userrole.includes("LPC") || this.userrole.includes("ADMIN")) {
      if (this.userrole.includes("TK")) {
        this.combroles = true;
      }
      else {
        this.onlylpcadmin = true;
      }
    }

  }

  getLoginUserMail() {
    this.userservice.getLoginUser().subscribe(res => {
      this.loggedInId = res.rows.id;

      // this.modalService.open(largeDataModal, { size: 'xl', windowClass: 'modal-holder', centered: true });
    })
  }

  forApprovalDisplayFlag(task: any) {
    if (task.statusCode != 'For Approval') {
      return true;
    }
    else {
      if (task.approver == this.loggedInId || this.userrole.includes("ADMIN")) {
        return false;
      }
      return true;
    }
  }

  checkPastDue(date: any, status: any) {
    if (!date)
      return false;
    date = new Date(date);
    let x = date && (date < this.today) && (status != 'Approved') ? true : false;
    return x;
  }
  isValidDueDate() {

    let projectDueDate = new Date(this.taskForm.controls['projectDueDate'].value);
    let createdOn = new Date(this.taskForm.controls['createdOn'].value);
    let taskDueDate = new Date(this.taskForm.controls['taskDueDate'].value);
    if (projectDueDate && createdOn && this.filterService.compareDate(taskDueDate, createdOn) == -1) {
      this.dueDateError = true;
      this.taskForm.controls['taskDueDate'].setErrors({ 'incorrect': true });
      return false;

    }
    else {
      this.dueDateError = false;
      this.taskForm.controls['taskDueDate'].setErrors(null);
      return true;
    }
  }
  dateContains(date: any, minDate: any, maxDate: any) {
    if (date.getTime() >= minDate.getTime() && date.getTime() <= maxDate.getTime()) {
      return true;
    } else {
      return false;
    }
  }

  editTask(task: any) {
    this.loading=true;
    let payload={"filters":[{"field":"qbTaskId","operator":"contains","value":task.qbTaskId}],"sort":[]}
    this.taskCatalogue.getCatList(1,10,payload).subscribe(res =>{
      this.catalogueRecord=res?.data?.filter((row:any)=>row.isActive?.toUpperCase()=='ACTIVE')
      if(this.catalogueRecord?.length==0){
        this.viewTask(task)
        this.title='Edit Task';
        setTimeout(() => {
          console.log("Errr")
          this.messageService.add({ severity: 'error', detail: "This task in Task Catalogue is In-Active with Quickbook ID :"+task.qbTaskId });
        }, 100);
        return;
      }else{
        this.catalogueRecord=this.catalogueRecord[0]
        task['catEnforceMax']=this.catalogueRecord.enforceMax;
      }
      this.loading=true;
      this.taskDialog = true;
      this.viewOnly = false;
      this.title = "Edit Task";
      const stateControl = this.taskForm.get('quantityState');
      const numberControl = this.taskForm.get('quantityNumber');
      task.isBillable = this.filterService.getStrBoolean(task.isBillable);
      task.isCompleted = this.filterService.getStrBoolean(task.isCompleted);
      task.includeToday = this.filterService.getStrBoolean(task.includeToday);
  
      if (task.quantityType == "State") {
        this.showQuantityState = true;
        if (task.quantityRelated == 'Yes') {
          stateControl.setValidators([Validators.required]);
          this.clearFormValidation('quantityNumber')
        } else {
          this.clearFormValidation('quantityState')
          this.clearFormValidation('quantityNumber')
        }
        task.quantityState = task.quantityState ? task.quantityState.map((obj: any) => obj.stateCode) : [];
      } else {
        if (task.quantityRelated == 'Yes') {
          numberControl.setValidators([Validators.required]);
          this.clearFormValidation('quantityState')
        } else {
          this.clearFormValidation('quantityState')
          this.clearFormValidation('quantityNumber')
        }
        this.showQuantityState = false;
      }
  
      task.taskDueDate = task.taskDueDate ? this.datePipe.transform(task.taskDueDate, "MM/dd/yyyy") : this.datePipe.transform(task.projectDueDate, "MM/dd/yyyy");
      task.createdOn = task.createdOn ? this.datePipe.transform(task.createdOn, "MM/dd/yyyy") : new Date();
      task.projectDueDate = task.projectDueDate ? this.datePipe.transform(task.projectDueDate, "MM/dd/yyyy") : null;
      task.isStateRelated = task.isStateRelated && typeof(task.isStateRelated)=='string' && task.isStateRelated.toUpperCase()=='YES' ? task.isStateRelated : "No";
      task.countryRelated = task.countryRelated && typeof(task.countryRelated)=='string' && task.countryRelated.toUpperCase()=='YES' ? task.countryRelated : "No";
      // if (task.projectIsBillable && task.projectIsBillable == 'Yes' && (!task.isBillable)) {
      //   task.isBillable = 'Yes'
      // } else {
      //   task.isBillable = task.isBillable ? task.isBillable : "No";
      // }
      task.isBillable = task.isBillable && typeof(task.isBillable)=='string' && task.isBillable.toUpperCase()=='YES'? task.isBillable : "No";
      task.country = task.country ?task.country :"United States";
      this.taskForm.patchValue(task);
      this.taskForm.enable();
      this.taskForm.controls['timeEstimate'].value = this.filterService.convertTimeToDate(task.timeEstimate);
      this.taskForm.controls['maxTime'].value = this.filterService.convertTimeToDate(task.maxTime);
      this.consolidatedTimeRecordsChange(task.consolidatedTimeRecords, task.holdTimeUntilComp);
      this.disableTaskOptionalFields()
      
      if (task.isStateRelated != "Yes") {
        this.taskForm.controls['stateCode'].disable()
        this.showStateCode = false;
      }
      if (task.countryRelated != "Yes") {
        this.taskForm.controls['country'].disable()
        this.showCountry = false;
      }
      if (!task.quantityType) {
        this.taskForm.controls['quantityState'].disable()
        this.taskForm.controls['quantityNumber'].disable()
      }
  
      if (task.isApprovalRequired == 'Yes') {
        this.taskForm.get('approver').setValidators([Validators.required]);
      } else {
        this.clearFormValidation('approver')
      }
      if (!this.userrole.includes('ADMIN')) {//SKIP THIS BLOCK FOR ADMIN USER
        if (task.statusCode == 'Accepted' || task.statusCode == 'For Approval') {//Not ADMIN 
          this.taskForm.disable();
          this.taskForm.controls['isCompleted'].enable();
          this.taskForm.get('isCompleted').setValidators([Validators.required]);
          if (!this.tkdashboard && !this.onlytk) {//DON'T ALLOW TK TO EDIT TK RATING AND DUE DATE
            if (task.isCompleted == 'No' || !task.isCompleted) {
              this.tkRatingMand = false;
              this.taskForm.controls['taskDueDate'].enable();
              this.taskForm.get('taskDueDate').setValidators([Validators.required]);
              this.taskForm.controls['tkRating'].disable();
              this.clearFormValidation('tkRating')
            }
            else if (task.isCompleted == 'Yes') {
              this.tkRatingMand = true;
              this.taskForm.controls['tkRating'].enable();
              this.taskForm.get('tkRating').setValidators([Validators.required]);
            }
          }
        } else {
          this.clearFormValidation('isCompleted');
        }
      }
      
      if ((this.userrole.includes('ADMIN') || this.userrole.includes('LPC')) && (!this.tkdashboard && !this.onlytk)) {//enable only for LPC and ADMIN
  
        this.taskForm.controls['isCompleted'].enable();
        this.taskForm.get('isCompleted').setValidators([Validators.required]);
        if (task.isCompleted == 'Yes') {
          this.tkRatingMand = true;
          if (task.tkRating == 'Selected' || task.tkRating == null) { Swal.fire('TK Rating must be entered!'); }
          this.taskForm.controls['tkRating'].enable();
          this.taskForm.get('tkRating').setValidators([Validators.required]);
        } else {
          this.tkRatingMand = false;
          this.taskForm.controls['tkRating'].disable();
          this.clearFormValidation('tkRating')
        }
        this.lpcAccepAssignFields(task)
      }
      this.onEnforceMaxChange();
  
    });
      
  }
  lpcAccepAssignFields(task:any){
    this.taskForm.controls['isCompleted'].enable();
    if(this.filterService.getflagBoolean(task.stateRelated)){
      this.taskForm.controls['stateCode'].enable();
    }
    this.taskForm.controls['timeEstimate'].enable();
    this.taskForm.controls['maxTime'].enable();
    this.taskForm.controls['isBillable'].enable();
    this.taskForm.controls['consolidatedTimeRecords'].enable();
    this.taskForm.controls['isRequireEstimate'].enable();
    this.taskForm.controls['isApprovalRequired'].enable();
    this.taskForm.controls['taskDueDate'].enable();
    this.taskForm.controls['dueDaySlot'].enable();
    this.taskForm.controls['openEnded'].enable();
    this.taskForm.controls['approver'].enable();
    // this.taskForm.controls['practiceAreaCode'].enable();
    this.taskForm.controls['reviewStatus'].enable();
    this.taskForm.controls['notes'].enable();
    this.taskForm.controls['description'].enable();
  }

  onIsApprovalRequiredChange(event: any) {
    if (event.target.value == 'Yes') {
      this.taskForm.get('approver').setValidators([Validators.required]);
      this.taskForm.get('approver').updateValueAndValidity();
    } else {
      this.clearFormValidation('approver')
    }
  }
  disableTaskOptionalFields() {
    
    this.taskForm.controls['qbTaskId'].disable()
    this.taskForm.controls['taskName'].disable()
    this.taskForm.controls['projectName'].disable()
    this.taskForm.controls['rateTypeCode'].disable()
    this.taskForm.controls['isCompleted'].disable()
    this.taskForm.controls['actualTime'].disable()
    this.taskForm.controls['isStateRelated'].disable()
    this.taskForm.controls['rateTypeCode'].disable()
    this.taskForm.controls['countryRelated'].disable()
    // this.taskForm.controls['isEnforceMaximum'].disable()
    this.taskForm.controls['tkRating'].disable()
  }
  closeDialog() {
    this.viewOnly = false;
    this.taskDialog = false;
    this.taskAssignDialog = false;
    this.acceptTaskDialog = false;
    this.addTRDialog = false;
    this.enableDeleteTask = false;
    this.enableReassignTask = false;
    this.submitted = false;
    this.showCountry = true;
    this.showStateCode = true;
    this.viewTaskAssignmentDialog = false;
    this.includeToday = false;
    this.dueDateError = false;
    this.taskAssignedUsers = [];
    this.rejectTimeRecords = false;
    this.deleteTRAR = false;
    this.deleteConfirmationDialog = false;
    this.forApprovalDialog = false;
    this.accbtnflag = true;
    this.rejbtnflag = true;
    this.showConfirmAlert = false;
    this.confirmMessage = "";
    this.confirmMsgCategory = "";
    this.tkRatingMand = false;
    this.taskForm.reset();
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }

  openAssignTask(task: any, deleteTask: any = false, reassign: boolean = false) {
    this.openTask = task;
    this.viewTask(task);
    this.taskAssignDialog = true;


    if (reassign) {

      this.taskService.getTKAssignmentDetails(task.id).subscribe(res => {
        this.enableReassignTask = true;
        if (res.data?.assignments?.length > 0) {
          this.assignedTkUser = res.data.assignments.filter((obj: any) => obj.assignmentStatus != "Rejected");
          this.assignedTkUserName = this.openTask.projTaskAssignmentUsername;
        }
        this.reassignReason = "";
        this.loadTKUsersGrid(task.id, false, true)

      })
      this.title = "Reassign Task To Time Keeper";
    }
    else {

      if (task.statusCode == "Open") {
        this.title = "Assign Task To Time Keeper";
        this.loadTKUsersGrid(task.id, false)
      } else {
        this.loadingTkUsers = true;
        this.taskService.getTKAssignmentDetails(task.id).subscribe(res => {
          this.viewTaskAssignmentDialog = true;
          this.loadingTkUsers = false;
          this.taskAssignedUsers = res.data.assignments;
          this.taskAssignedUsers.forEach((obj: any) => obj.tkAcceptedTime = this.filterService.convertTimeNumToStr(obj.tkAcceptedTime));
          this.includeToday = res.data.projectTask.includeToday == "NO" ? false : true;
          if (deleteTask) {
            this.title = "Delete Task";
          }
          else
            this.title = "View Task Assignments ";
        })
      }
    }
  }

  loadDayWiseData(taskid: any, statusCode: any) {
    this.loadingDayWiseData = true;
    this.rejectresponse = []
    this.accepteddaywiseData = []
    if (this.lpcdashboard || this.onlylpcadmin) {
      this.taskService.getAcceptedAdmin(taskid).subscribe(res => {
        this.loadingDayWiseData = false;
        let temp = res && res.data.assignments ? res.data.assignments : [];
        for (var i = 0; i < temp.length; i++) {
          var x = temp[i].details
          x['tkMail'] = temp[i].timekeeper.mainEmail
          x['tkId'] = temp[i].timekeeper.id
          x['projTaskId'] = res.data.projTask.id
          this.accepteddaywiseData.push(x)
        }

      })
    }
    else {
      if (statusCode == "Assigned") {
        this.taskService.getDayWiseData(taskid).subscribe(res => {
          this.loadingDayWiseData = false;
          this.daywiseData = res && res.data ? res.data : [];
          this.assignedTime = this.daywiseData[0] ? this.daywiseData[0].assignedTime : 0;
          this.daywiseData.map((obj: any) => {
            obj['timelist'] = this.timeSlot;
            // .slice(0, obj.availableTime / 30);
            obj['minutes'] = this.filterService.convertTimeToDate(obj['minutes']);
            obj['date'] = obj.availableDate;
            obj['projTaskId'] = this.acceptDdata.id;
            obj['availableTime'] = this.filterService.convertTimeNumToStr(obj['availableTime']);
            obj['maxAcceptTime'] = this.filterService.convertTimeToDate(this.filterService.convertStrToNum(obj['availableTime']));
          })
        })
      }
      else {
        this.taskService.getAcceptedDaywise(taskid).subscribe(res => {
          this.loadingDayWiseData = false;
          this.accepteddaywiseData = res && res.data && res.data.assignments && res.data.assignments && res.data.assignments[0] ? res.data.assignments[0].acceptance : [];
          this.accepteddaywiseData.map((obj: any) => {
            obj['tkMail'] = res.data.assignments[0].timekeeper.mainEmail
            obj['projTaskId'] = this.acceptDdata.id
            obj['tkUserId'] = res.data.assignments[0].timekeeper.id
            obj['minutes'] = this.filterService.convertTimeToDate(obj['minutes']);
            obj['availableTime'] = this.filterService.convertTimeNumToStr(obj['availableTime']);
            obj['maxAcceptTime'] = this.filterService.convertTimeToDate(this.filterService.convertStrToNum(obj['availableTime']));
          })
        })
      }
    }
  }

  forApprovalChange(event: any, day: any) {

    if (event.target.checked)
      this.rejectresponse.push(day)
    else
      this.rejectresponse = this.rejectresponse.filter((item: any) => item.tkId !== day.tkId);

  }

  onReassignReasonChange(reason: any) {
    this.reassignReason = reason;
    if (reason)
      this.disableReassignbtnFlag = false;
    else
      this.disableReassignbtnFlag = true;
  }
  onDeleteReasonChange(reason: any) {
    this.deleteReason = reason;
    if (reason)
      this.disableDelbtnFlag = false;
    else
      this.disableDelbtnFlag = true;
  }

  openAcceptTask(task: any) {
    this.acceptTaskDialog = true;
    this.title = "Accept/Reject Task";
    this.acceptDdata = task;
    this.acceptDdata.includeToday = task.includeToday == "YES" ? true : false;
    task.estimatehours = this.acceptDdata.timeEstimate;
    this.taskForm.patchValue(task);
    this.taskForm.controls['timeEstimate'].value = this.filterService.convertTimeToDate(task.timeEstimate);
    this.taskForm.controls['maxTime'].value = this.filterService.convertTimeToDate(task.maxTime);
    this.taskForm.disable();
    // if (task.isRequireEstimate == 'Yes' && task.statusCode == "Assigned") {
    //   this.taskForm.controls['timeEstimate'].enable();
    // }
    if (this.acceptDdata.statusCode != 'Assigned') {
      this.accbtnflag = false;
    }
    if (this.acceptDdata.statusCode == 'Approved') {
      this.rejbtnflag = false;
    }
    this.loadDayWiseData(task.id, task.statusCode)

  }
  openAddTR(task: any, deleteTask: any = false) {
    this.timeRecords = []
    this.addTRDialog = true;
    task.isBillable = this.filterService.getflagBoolean(task.isBillable);
    task.isCompleted = this.filterService.getflagBoolean(task.isCompleted);
    task.includeToday = this.filterService.getflagBoolean(task.includeToday);
    task.timeEstimate = this.filterService.convertTimeToDate(task.timeEstimate);
    task.maxTime = this.filterService.convertTimeToDate(task.maxTime);
    this.timeRecordsDueDate = new Date(task.taskDueDate);
    this.timeRecordsStartDate = task.projectStartDate ? new Date(task.projectStartDate) : null;
    task.timeRecordsLoggedTime = this.filterService.convertTimeToDate(task.timeRecordsLoggedTime);
    this.taskForm.patchValue(task);
    // this.taskForm.controls['isCompleted'].value = task.isCompleted == "Yes" ? true : false;
    // this.taskForm.controls['isBillable'].value = task.isBillable == "Yes" ? true : false;
    this.taskForm.controls['isEnforceMaximum'].value = this.taskForm.controls['isEnforceMaximum'].value ? this.taskForm.controls['isEnforceMaximum'].value : 'No';
    this.taskForm.disable();
    this.taskForm.controls['isCompleted'].enable();
    this.taskForm.controls['isBillable'].enable();
    this.title = "Add Time Record";
    this.acceptDdata = task;
    // this.estimatehours=this.acceptDdata.timeEstimate;
    this.disableADDTR = this.taskForm.controls.isCompleted.value

    if (deleteTask) {
      this.taskForm.disable();
      this.disableADDTR = true;
      this.loadTimeRecordData(task.id, true);
      this.enableDeleteTask = true;
    }
    else {
      this.loadTimeRecordData(task.id);
    }

  }
  loadTimeRecordData(taskId: any, deleteTask: any = false) {
    this.loadingData = true;

    this.taskService.getExistingTR(taskId).subscribe(res => {
      this.loadingData = false;
      this.acceptedDates = res.data.acceptedDates;
      this.timeRecords = res.data.timeRecords;
      this.timeRecords.map((obj: any) => {
        // obj["date"] = new Date(obj["date"])
        obj["enteredTime"] = this.filterService.convertTimeToDate(obj["enteredTime"]);
        obj["dateValid"] = true;
        // obj["datefutureValid"] = true;
        obj["descValid"] = true;
        obj["timeValid"] = true;
        obj["disableDel"] = true;
        if (obj["timeRecStatus"] != "In Progress") {
          obj["disableInp"] = true;
        }
        if (deleteTask) {
          obj["disableInp"] = true;
        }
        obj["quantityType"]=this.taskForm.getRawValue().quantityType;
        if(obj["quantityType"]=='State'){
          obj["quantityStateList"]=obj.quantity?obj.quantity.split(","):[];
        }else{
          obj["quantity"]=obj.quantity;
        }
      })

    })
    this.appConfigService.getConfigurationsById(null).subscribe(res => {
      let data = res.data.filter((obj: any) => obj.name == "Time Closing Date");
      const timeclosingDate = new Date(data[0].value);
      const projectStartDate = this.timeRecordsStartDate
      const projectDueDate = this.timeRecordsDueDate
      const today = new Date()
      let trstart = timeclosingDate;
      let trend = projectDueDate;

      if (timeclosingDate.getTime() < projectStartDate.getTime()) {
        trstart = projectStartDate;
      }

      if (projectDueDate.getTime() > today.getTime()) {
        trend = today;
      }
      this.timeRecordsStartDate = trstart
      this.timeRecordsDueDate = trend

    })
  }

  accept() {
    this.messageService.clear();
    var temp = []
    let enteredTime = 0
    let availableTime = 0
    for (var i of this.daywiseData) {
      if (this.filterService.convertDateToTime(i.minutes) > 0) {
        temp.push({ "date": i.availableDate, "minutes": this.filterService.convertDateToTime(i.minutes) })
        enteredTime += this.filterService.convertDateToTime(i.minutes)
        availableTime += this.filterService.convertStrToNum(i.availableTime)
      }
    }
    if(temp.length==0){
      this.messageService.add({ severity: 'error', detail: "Please Enter Time to Accept this task. " });
      return;
    }


    if (this.acceptDdata.isEnforceMaximum == 'Yes' && this.acceptDdata.openEnded=='No' && (this.acceptDdata.maxTime ? availableTime < this.acceptDdata.maxTime : true)) {
      this.messageService.add({ severity: 'error', detail: "User Don't Have Sufficient Available Time to Accept this task." });
      return;
    }

    // if (this.acceptDdata.isEnforceMaximum == 'Yes' && (this.acceptDdata.maxTime ? enteredTime > this.acceptDdata.maxTime : enteredTime > this.acceptDdata.timeEstimate)) {
    //   this.messageService.add({ severity: 'error', detail: (this.acceptDdata.maxTime && (enteredTime > this.acceptDdata.maxTime)) ? "Accepted Time Should not Exceed Max Time" : "Accepted Time Should not Exceed Estimated Time" });
    //   return;
    // }

    let payload = {
      "assignments": [{
        "tkId": this.daywiseData[0].tkUserId,
        "projTaskId": this.acceptDdata.id,
        "status": "ACCEPT",
        "timeEstimate": this.filterService.convertDateToTime(this.taskForm.value.timeEstimate),
        "tkAcceptanceDetails": temp
      }
      ]
    }
    this.taskService.acceptTastbyTK(payload).subscribe(res => {
      if (res.message == "Successful.") {
        this.messageService.add({ severity: 'success', detail: "Accepted Successfully!" });
        this.accbtnflag = false;
        this.closeDialog();
      }
      else {
        this.messageService.add({ severity: 'error', detail: res.message });
      }

    }, res => {
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })

  }

  reject() {
    this.rejbtnLoading = true;
    this.messageService.clear();
    if ((this.lpcdashboard || (!this.onlytk && !this.tkdashboard))) {
      this.taskService.acceptTastbyTK({ "assignments": this.rejectresponse }).subscribe(res => {//for approval
        if (res.message == "Successful.") {
          this.messageService.add({ severity: 'success', detail: "Rejected Successfully!" });
          this.rejbtnLoading = false;
          this.rejbtnflag = false;
        }
        else {
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, res => {
        this.rejbtnLoading = false;
        this.messageService.add({ severity: 'error', detail: res.error.message });
      })
    }
    else {
      if ((!this.daywiseData || this.daywiseData.length == 0) &&
        (!this.accepteddaywiseData || this.accepteddaywiseData.length == 0)) {
        this.rejbtnLoading = false;
        this.messageService.add({ severity: 'error', detail: "No Assignments Found to Accept/Reject for the Task" });
        return;
      }
      let payload = {
        "assignments": [{
          "tkId": this.daywiseData ? this.daywiseData[0].tkUserId : this.accepteddaywiseData[0].tkUserId,
          "projTaskId": this.acceptDdata.id,
          "status": "REJECT",
        }]
      }
      this.taskService.acceptTastbyTK(payload).subscribe(res => {
        if (res.message == "Successful.") {
          this.messageService.add({ severity: 'success', detail: "Rejected Successfully!" });
          this.rejbtnLoading = false;
          this.rejbtnflag = false;
          this.closeDialog();
        }
        else {
          this.rejbtnLoading = false;
          this.messageService.add({ severity: 'error', detail: res.message });
        }
      }, res => {
        this.rejbtnLoading = false;
        this.messageService.add({ severity: 'error', detail: res.error.message });
      })
    }

  }

  loadTKUsersGrid(taskId: any, includeToday: any, isReassign: boolean = false) {
    this.loadingTkUsers = true;
    this.taskService.getTKUsersList(taskId, includeToday, isReassign).subscribe(res => {
      this.loadingTkUsers = false;
      this.tkUsers = res && res.data ? res.data : [];
      this.tkUsers.map((obj: any) => {
        obj['mustAcceptList'] = this.timeSlot;
        // .slice(0, obj.totalAvailableTime / 30);
        obj['mustAcceptTime'] = 0;
        obj['totalAvailableTimeStr'] = this.timeConvert(obj['totalAvailableTime']);
        obj['loading'] = false;
      })
    })
  }
  onEnforceMaxChange(){

    if(this.taskForm.controls['rateTypeCode'].value=="Fixed Rate"){
      if(this.catalogueRecord.enforceMax=="Yes" ){
        this.taskForm.controls['maxTime'].disable();
        this.clearFormValidation('maxTime')
        this.taskForm.controls['isEnforceMaximum'].disable();
        this.clearFormValidation('isEnforceMaximum')
      }else if(this.taskForm.controls['isEnforceMaximum'].value=="Yes" ){
        this.taskForm.controls['maxTime'].setValidators([Validators.required]);
        this.taskForm.controls['maxTime'].updateValueAndValidity();
        this.taskForm.controls['maxTime'].enable();
      }else if(this.taskForm.controls['isEnforceMaximum'].value=="No" ){
        this.clearFormValidation('maxTime')
        this.taskForm.controls['maxTime'].enable();
      }else{
        this.clearFormValidation('maxTime')
        this.taskForm.controls['maxTime'].enable();
      }
      
    }else if(this.taskForm.controls['rateTypeCode'].value=="Hourly"){
      if(this.taskForm.controls['isEnforceMaximum'].value=="Yes" ){
        this.taskForm.controls['maxTime'].setValidators([Validators.required]);
        this.taskForm.controls['maxTime'].updateValueAndValidity();
        this.taskForm.controls['maxTime'].enable();
      }else{
        this.clearFormValidation('maxTime')
        this.taskForm.controls['maxTime'].enable();
      }
    }else{
        this.clearFormValidation('maxTime')
    }
  }

  timeConvert(n: any) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    let hStr = rhours ? rhours + " hrs " : "";
    let minsStr = rminutes ? rminutes + " mins" : "";
    return hStr + minsStr;
  }

  includeTodayAssignment(taskId: any, event: any) {
    this.includeToday = event.target.checked;
    this.loadTKUsersGrid(taskId, event.target.checked, this.enableReassignTask);
  }

  assignTaskTKUser() {
    if (!this.TKAssignedUser) {
      this.assignedInvalid = true;
    }
  }

  openAssignNewTask() {
    this.title = "Assign Task to Project"
    this.newTaskDialog = true;
  }

  projectChange(project: any) {
    this.projectAssignedTasks = project.projectTasks;
  }

  closeNewTaskDialog() {
    this.selectedProject = "";
    this.projectAssignedTasks = [];
    this.newTask = "";
    this.accbtnflag = true;
    this.rejbtnflag = true;
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }
  addTemplateTask() {
    this.messageService.clear()
    const payload = {
      projectId: this.selectedProject.id,
      taskIds: [this.newTask.id]
    }
    if (this.assignBtnLoading)
      return;
    this.assignBtnLoading = true;
    try {
      this.taskService.assignTask(payload).subscribe(
        res => {
          this.loadAllProjects()
          this.assignBtnLoading = false;
          if (res.message) {
            this.viewOnly = true;
            this.messageService.add({ severity: 'success', detail: 'Task details have been assigned successfully' });
          } else {
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        }, res => {
          this.assignBtnLoading = false;
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.assignBtnLoading = false;
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.error.message });
    }
  }
  padZero(number: number) {
    return number.toString().padStart(2, '0');
  }
  deepCopy(obj: any): any {
    let copy: any;

    if (obj instanceof Date) {
      copy = new Date(obj.getTime()); // Create a new Date object with the same timestamp
    } else if (Array.isArray(obj)) {
      copy = obj.map(item => this.deepCopy(item)); // Recursively copy each item in the array
    } else if (typeof obj === 'object' && obj !== null) {
      copy = {};
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          copy[key] = this.deepCopy(obj[key]); // Recursively copy object properties
        }
      }
    } else {
      copy = obj; // Primitive values are directly assigned
    }

    return copy;
  }

  isDuplicateTR() {
    var uniqueArray: any = [];
    var map = new Map();
    let tr1 = this.deepCopy(this.timeRecords);
    let flag = true;
    tr1.forEach((tr: any, index: number) => {
      let x = new Date(tr.date);
      // let date = x.toISOString().slice(0, 10);
      let date = this.filterService.convertDateToFormattedStr(x)

      let val = map.get(date)
      console.log(val)
      if (val) {
        val.forEach((description: any) => {
          if (tr1[index].description == description) {
            flag = false;
            this.messageService.clear();
            this.messageService.add({ severity: 'error', detail: 'No Duplicate Descriptions on same day' });
          }
          else {
            map.get(date)?.push(tr1[index].description);
          }
        })

      }
      if (index == 0) {
        map.set(date, [tr1[index].description]);
      }
      if (!map.get(date)) {
        map.set(date, [tr['description']]);
      }
    });
    if (flag) return true
    else return false;
  }

  validateInputs() {
    if (this.timeRecords.length == 0) {
      return false
    }
    let tr = this.deepCopy(this.timeRecords);
    let flag = true;
    for (let i = 0; i < tr.length; i++) {
      this.timeRecords[i].dateValid = tr[i].date ? true : false;
      // this.timeRecords[i].datefutureValid = (new Date(tr[i].date)<=new Date())  ? true : false;
      // if(!this.timeRecords[i].dateValid){
      //   this.timeRecords[i].datefutureValid=true;
      // }
      this.timeRecords[i].descValid = tr[i].description ? true : false;
      this.timeRecords[i].timeValid = tr[i].enteredTime ? true : false;
      flag = flag && this.timeRecords[i].dateValid && this.timeRecords[i].descValid && this.timeRecords[i].timeValid;
      //  && this.timeRecords[i].datefutureValid;  
    }
    return flag;
  }

  saveTR() {
    this.messageService.clear();
    let duplicateCheck = this.isDuplicateTR()
    if (this.validateInputs() && duplicateCheck) {

      let validtime = this.filterService.convertDateToTime(this.taskForm.getRawValue().maxTime) ? this.filterService.convertDateToTime(this.taskForm.getRawValue().maxTime) : this.filterService.convertDateToTime(this.taskForm.getRawValue().timeEstimate)
      let tr = this.deepCopy(this.timeRecords);
      let timeSum = 0;
      let timeRecordsList: any = [];
      let record:any={}
      let quantityInvalidFlag=false;
      // tr.forEach((i: any) => {
      for(let i=0;i<tr.length;i++){
        record=tr[i]  
        let x = new Date(record.date);
        record.date = this.filterService.convertDateToFormattedStr(x);
        record.enteredTime = this.filterService.convertDateToTime(record.enteredTime)
        delete record.dateValid;
        delete record.descValid;
        delete record.timeValid;
        record.quantity=record.quantityStateList &&record.quantityType?.toUpperCase()=='STATE' ?record.quantityStateList.join(','):record.quantity;
        if((this.taskForm.getRawValue().quantityRelated == 'Yes')&& (!record.quantity || ((record.quantityType?.toUpperCase()=='NUMBER') && record.quantity<=0))){
          quantityInvalidFlag=true;
          break;
        }
        // delete i.datefutureValid;
        timeRecordsList.push(record);
        timeSum += record.enteredTime;
      }

      if(quantityInvalidFlag){  
        this.messageService.add({ severity: 'error', detail: 'Please enter valid quantity (cannot be empty).' });
        return;
      }

      let validFlag = (this.taskForm.getRawValue().isEnforceMaximum != 'No' && this.taskForm.getRawValue().openEnded=='No') ? timeSum <= validtime : true;
      if (validFlag) {
        let payload: any = {};
        payload.taskId = this.taskForm.getRawValue().id;
        payload.billable = this.taskForm.getRawValue().isBillable;
        payload.timeRecords = timeRecordsList;
        payload.taskCompleted = this.taskForm.getRawValue().isCompleted;
        this.taskService.saveTR(payload).subscribe(
          res => {
            if (res.data.statusCode == 200) {
              this.messageService.add({ severity: 'success', detail: 'Time Record added successfully' });
              this.closeDialog();
            }
            else {
              this.messageService.add({ severity: 'error', detail: res.message?res.message:res.data.message?res.data.message:"Something went wrong while saving Time Record." });
            }
          }, res => {
            this.messageService.add({ severity: 'error', detail: res.error.message?res.error.message:res.error.data.message?res.error.data.message:"Something went wrong while saving Time Record." });
          }
        )
      }
      else {

        this.messageService.add({ severity: 'error', detail: 'The total logged time must not exceed the max time or estimated time.' });
      }

    }
    else {
      if (duplicateCheck) {
        this.messageService.add({ severity: 'error', detail: 'Enter valid timerecords' });
      }
    }
  }
  resetForm() {
    this.trForm.reset();
  }


  deleteTR(recordIndex: any) {
    this.timeRecords.splice(recordIndex, 1);
  }
  deleteTask(task: any) {
    this.messageService.clear();
    try {
      this.taskService.deleteTask(this.selectedProject.id, task.id).subscribe(
        res => {
          this.btnLoading = false;
          this.loadAllProjects();
          if (res.message) {
            this.messageService.add({ severity: 'success', detail: 'Task details have been deleted successfully' });
          } else {
            this.taskForm.enable();
            this.disableTaskOptionalFields()
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        }, res => {
          this.btnLoading = false;
          this.taskForm.enable();
          this.disableTaskOptionalFields()
          console.log(res)
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.btnLoading = false;
      this.taskForm.enable();
      this.disableTaskOptionalFields()
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.error.message });
    }
  }

  saveAssignment(user: any) {
    if (!user.mustAcceptTime || Number(user.mustAcceptTime) < 0) {
      this.messageService.add({ severity: 'error', detail: "Please select time for Must Accept W/I." });
      return;
    }
    this.messageService.clear();
    user.loading = true;
    let payload = {
      "tkId": user.tkUserId,
      "projTaskId": this.openTask.id,
      "projId": this.openTask.projectId,
      "mustAcceptWithin": Number(user.mustAcceptTime),
      "includeToday": this.includeToday
    }
    this.taskService.assignTaskToTK(payload).subscribe(res => {
      this.messageService.clear();
      this.messageService.add({ severity: 'success', detail: user.tkFirstName + " Assigned Successfully." });
      user.loading = false;
      setTimeout(() => {
        this.taskAssignDialog = false;
        this.taskDialog = false;
      }, 100);
    }, res => {
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }

  saveReAssignment(user: any) {
    if (!Number(user.mustAcceptTime)) {
      this.messageService.add({ severity: 'error', detail: "Please Select time for Must Accept Within!" });
      return;
    }
    user.loading = true;
    let payload: any = {
      "tkId": this.openTask.projTaskAssignmentTkId ? this.openTask.projTaskAssignmentTkId : "",
      "projTaskId": this.openTask.id,
      "projId": this.openTask.projectId,
      "mustAcceptWithin": Number(user.mustAcceptTime),
      "includeToday": this.includeToday,
    }
    payload.reassignReason = this.reassignReason;
    payload.reassignTkId = user.tkUserId;
    payload.taskReassign = true;
    payload.rejectTimeRecords = this.deleteTRAR;
    if (this.deleteTRAR) {
      user.DelLoading = true;
      user.loading = false;
    }
    this.disableReassignbtnFlag = true;
    this.taskService.assignTaskToTK(payload).subscribe(res => {
      this.messageService.clear();
      this.messageService.add({ severity: 'success', detail: user.tkFirstName + " Assigned Successfully." });
      user.loading = false;
      setTimeout(() => {
        this.closeDialog()
      }, 100);
    }, res => {
      this.disableReassignbtnFlag = false;
      user.loading = false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }

  saveTask(saveTaskFlag?: any) {
    this.messageService.clear();
    if (saveTaskFlag == false) {
      this.closeDialog();
      return;
    }
    if (this.btnLoading)
      return;

    // console.log(this.taskForm)
    if(this.taskForm.controls['countryRelated'].value =="Yes" && this.taskForm.controls['country'].value !="United States" && this.taskForm.controls['country'].value!="Canada" ){
      this.taskForm.controls['stateCode'].value="N/A";
      this.taskForm.controls['stateCode'].patchValue("N/A");
      this.taskForm.get('stateCode').updateValueAndValidity();
    }
    this.submitted = true;
    
    if (this.taskForm.valid) {

      let projectDueDate = this.taskForm.controls['projectDueDate'].value ? new Date(this.taskForm.controls['projectDueDate'].value) : null;
      let taskDueDate = this.taskForm.controls['taskDueDate'].value ? new Date(this.taskForm.controls['taskDueDate'].value) : null;

      if (!this.isValidDueDate()) {
        this.messageService.add({ severity: 'error', detail: "Validation Error while saving details" });
        return;
      }

      if (!this.tkdashboard && taskDueDate && projectDueDate && this.filterService.compareDate(taskDueDate, projectDueDate) > 0 && !saveTaskFlag) {
        this.showConfirmAlert = true;
        this.confirmMessage = "Are you sure, You want to Exceed Project DueDate ?"
        this.confirmMsgCategory = "EXCEED_DUEDATE"
        return;
      }
      if (this.taskForm.controls['isCompleted'].value == "Yes" && !saveTaskFlag) {
        const id = this.taskForm.controls['id'].value
        if (id) {
          this.taskService.getExistingTR(id).subscribe(res => {
            if(res.data && res.data.timeRecords && res.data.timeRecords.length==0){
              this.showConfirmAlert = true;
              this.confirmMessage = "The Task you are completing has no time records.  Are you sure you want to complete this Task?"
            }else{
              this.persisTaskData();
            }
          });
        }
      }else{
        this.persisTaskData();
      }
    } else {
      this.messageService.add({ severity: 'error', detail: "Validation Error while saving details" });
    }
  }

  persisTaskData() {
    this.showConfirmAlert = false;

    this.btnLoading = true;

    const id = this.taskForm.controls['id'].value
    if (id)
      delete this.taskForm.value.id
    let payload = this.taskForm.getRawValue()
    payload.taskDueDate = this.datePipe.transform(payload.taskDueDate, "MM/dd/yyyy");
    payload.maxTime = this.filterService.convertDateToTime(payload.maxTime)
    payload.timeEstimate = this.filterService.convertDateToTime(payload.timeEstimate)
    payload.isRequireEstimate = payload.isRequireEstimate == "Yes" ? true : false;
    payload.approved = "Yes";
    payload.editIsCompletedOnly = false;
    delete payload.actualTime
    if (payload.statusCode == 'Accepted' || payload.statusCode == 'Rejected' || payload.statusCode == 'For Approval') {
      if (payload.isCompleted == 'Yes') {
        payload.editIsCompletedOnly = true;
      }
    }
    if (this.confirmMsgCategory == "EXCEED_DUEDATE") {
      payload.projectDueDate = payload.taskDueDate
    } else {
      delete payload.projectDueDate
    }

    this.taskForm.disable();
    try {
      this.taskService.saveTask(payload, id).subscribe(
        res => {
          this.btnLoading = false;
          if (res.message) {
            this.viewOnly = true;
            this.messageService.add({ severity: 'success', detail: 'Project ' + this.taskForm.controls['taskName'].value + ' details have been saved successfully' });
            setTimeout(() => {
              this.closeDialog();
            }, 500);
          } else {
            // this.taskForm.enable();
            // this.disableTaskOptionalFields()
            this.messageService.add({ severity: 'error', detail: res.message });
          }
        }, res => {
          this.btnLoading = false;
          // this.taskForm.enable();
          // this.disableTaskOptionalFields()
          this.messageService.add({ severity: 'error', detail: res.error.message });
        })
    } catch (error: any) {
      this.btnLoading = false;
      // this.taskForm.enable();
      // this.disableTaskOptionalFields()
      console.log(error)
      this.messageService.add({ severity: 'error', detail: error.error.message });
    }

  }

  forApproval(task: any) {
    this.title = "For Approval";
    this.forApprovalDialog = true;
    this.FAdisableFlag = false;
    this.rejectedReason = "";
    this.radioStatus = "approve";
    task.isBillable = this.filterService.getflagBoolean(task.isBillable);
    task.isCompleted = this.filterService.getflagBoolean(task.isCompleted);
    task.includeToday = this.filterService.getflagBoolean(task.includeToday);
    task.timeEstimate = this.filterService.convertTimeToDate(task.timeEstimate);
    task.timeRecordsLoggedTime = this.filterService.convertTimeToDate(task.timeRecordsLoggedTime);
    task.maxTime = task.maxTime ? this.filterService.convertTimeToDate(task.maxTime) : this.filterService.convertTimeToDate(0);
    this.taskForm.patchValue(task);
    this.taskForm.disable();
    this.taskForm.controls.isBillable.enable();
    this.taskForm.controls.tkRating.enable();
    this.loadTRData(task);
  }

  approveTask(taskForm: any) {
    this.faloading = true;
    var payload: any = [
      {
        "projTaskId": taskForm.controls.id.value,
        "billable": taskForm.value.isBillable,
        "status": "Approved",
        "tkRating": taskForm.controls.tkRating.value
      }
    ];
    this.taskService.approveOrRejectTask(payload).subscribe(res => {
      if (res.data[0].message == "SUCCESS") {
        this.faloading = false;
        this.FAdisableFlag = true;
        this.taskForm.controls.isBillable.disable();
        this.taskForm.controls.tkRating.disable();
        this.messageService.add({ severity: 'success', detail: 'Successfully Approved project task status.' });
      }
    }, res => {
      this.faloading = false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }
  rejectTask(taskForm: any) {
    this.faloading = true;
    var payload: any = [
      {
        "projTaskId": taskForm.controls.id.value,
        "status": "Rejected",
        "rejectReason": this.rejectedReason,
        "tkRating": taskForm.controls.tkRating.value
      }
    ];
    this.taskService.approveOrRejectTask(payload).subscribe(res => {
      if (res.data[0].message == "SUCCESS") {
        this.faloading = false;
        this.FAdisableFlag = true;
        this.taskForm.controls.isBillable.disable();
        this.taskForm.controls.tkRating.disable();
        this.messageService.add({ severity: 'success', detail: 'Successfully Rejected project task status.' });
      }
    }, res => {
      this.faloading = false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }
  loadTRData(task: any) {
    this.loadingTRData = true;
    this.taskService.getExistingTR(task.id).subscribe(res => {
      this.loadingTRData = false;
      this.timeRecords = res.data.timeRecords;
      if (this.timeRecords.length > 0) {
        this.timeRecords.map((obj: any) => {
          obj["date"] = new Date(obj["date"])
          obj["enteredTime"] = this.filterService.convertTimeToDate(obj["enteredTime"]);
          obj['quantityType']=task.quantityType;
          if(obj['quantityType']!="Number"){
            obj['quantityStateList']=task.quantity.split(",");
          }else{
            obj['quantity']=task.quantity
          }
        })
      }

    })
  }
  addTimeRecord(taskForm: any) {
    if (taskForm.descriptionTypeCode == 'Auto' || !taskForm.descriptionTypeCode) {
      this.timeRecords.push({
        description: taskForm.description ? taskForm.description : "",
        tkComment:taskForm.tkComment ? taskForm.tkComment : "",
        dateValid: true,
        datefutureValid: true,
        descValid: true,
        timeValid: true,
        disableDel: false,
        disableInp: false,
        disableQuantity:false,
        quantityType: taskForm.quantityType,
        quantityStateList:taskForm.quantityState?taskForm.quantityState.map((obj:any)=>obj.stateDesc):[],
        quantity:taskForm.quantityNumber
      })
    }
    else {
      this.timeRecords.push({
        tkComment:"",
        description: "",
        dateValid: true,
        datefutureValid: true,
        descValid: true,
        timeValid: true,
        disableDel: false,
        disableInp: false,
        disableQuantity: false,
        quantityType: taskForm.quantityType,
        quantityStateList:taskForm.quantityState?taskForm.quantityState.map((obj:any)=>obj.stateDesc):[],
        quantity:taskForm.quantityNumber
      })
    }
  }
  deleteTaskAPI(task: any) {
    this.disableDelbtnFlag = true;
    this.loadingData = true;
    let payload = {
      "projTaskId": task.value.id,
      "tkId": task.value.projTaskAssignmentTkId ? task.value.projTaskAssignmentTkId : "",
      "rejectTimerecords": this.rejectTimeRecords,
      "deleteReason": this.deleteReason
    }
    this.taskService.deleteTaskGrid(payload).subscribe((res: any) => {
      if (res.body != undefined && res.body.message == "Successful.") {
        this.loadingData = false;
        this.closeDialog();
        Swal.fire("Deleted Task Successfully!");
      }
    }, res => {
      this.disableDelbtnFlag = false;
      this.loadingData = false;
      this.messageService.add({ severity: 'error', detail: res.error.message });
    })
  }

  deleteTaskInGrid(task: any) {
    this.title = "Delete Task"
    task.timeRecordsLoggedTime = task.timeRecordsLoggedTime ? task.timeRecordsLoggedTime : -1;
    this.taskForm.patchValue(task);
    this.deleteReason = "";
    if (task.statusCode == 'Open') {
      this.deleteConfirmationDialog = true;
    }
    else if (task.statusCode == 'Assigned') {
      this.openAssignTask(task, true);
      this.enableDeleteTask = true;
    }
    else if (task.statusCode == 'Accepted' && task.timeRecordsLoggedTime < 0) {
      this.openAssignTask(task, true);
      this.enableDeleteTask = true;
    }
    else if (task.statusCode == 'Accepted' && task.timeRecordsLoggedTime > 0) {
      this.openAddTR(task, true);
    }
  }
  handleTabChange(table: Table, event: any) {
    this.loading = true;
    this.tasks = [];
    if (event.index == 0) {
      this.tkdashboard = false;
    } else {
      this.tkdashboard = true;
    }
    table.clear();
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }
  isCompletedOnchange(value: any) {
    if ((this.taskForm.get('statusCode').value == 'Accepted' || this.taskForm.get('statusCode').value == 'For Approval')
      && (this.userrole.includes('ADMIN') || this.userrole.includes('LPC'))
    ) {
      if (!this.tkdashboard) {
        if (value == 'Yes') {
          this.tkRatingMand = true;
          Swal.fire('TK Rating must be entered!');
          this.taskForm.get('taskDueDate').disable();
          this.taskForm.get('tkRating').enable();
          this.taskForm.get('tkRating').setValidators([Validators.required]);
          this.clearFormValidation('taskDueDate')
        } else {
          this.tkRatingMand = false;
          this.taskForm.get('taskDueDate').enable();
          this.taskForm.get('taskDueDate').setValidators([Validators.required]);
          this.taskForm.get('tkRating').disable();
          this.clearFormValidation('tkRating')
        }
      }
    }
  }
  clearFormValidation(value: any) {
    this.taskForm.get(value).clearValidators()
    this.taskForm.get(value).setErrors(null);
    this.taskForm.get(value).updateValueAndValidity();
  }

  clicked(task:any){
    console.log(task);
    this.selectedTask=task;
    this.showProjectsView=true;
    this.viewTaskName=task.projectName;
  }

  backToTasksGrid(){
    this.viewTaskName="";
    this.selectedTask=null;
    this.showProjectsView=false;
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }


  get timeEstimate() {
    return this.taskForm.get('timeEstimate');
  }
  get actualTime() {
    return this.taskForm.get('actualTime');
  }
  get stateCode() {
    return this.taskForm.get('stateCode');
  }
  get country() {
    return this.taskForm.get('country');
  }
  get consolidatedTimeRecords() {
    return this.taskForm.get('consolidatedTimeRecords');
  }
  get statusCode() {
    return this.taskForm.get('statusCode');
  }
  get quantityState() {
    return this.taskForm.get('quantityState');
  }
  get quantityNumber() {
    return this.taskForm.get('quantityNumber');
  }
  get approver() {
    return this.taskForm.get('approver');
  }
  get openEnded() {
    return this.taskForm.get('openEnded');
  }
  get approved() {
    return this.taskForm.get('approved');
  }
  get holdTimeUntilComp() {
    return this.taskForm.get('holdTimeUntilComp');
  }
  get quantityRelated() {
    return this.taskForm.get('quantityRelated');
  }
  get quantityType() {
    return this.taskForm.get('quantityType');
  }
  get isApprovalRequired() {
    return this.taskForm.get('isApprovalRequired');
  }
  get taskDueDate() {
    return this.taskForm.get('taskDueDate');
  }
  get isRequireEstimate() {
    return this.taskForm.get('isRequireEstimate');
  }
  get isBillable() {
    return this.taskForm.get('isBillable');
  }
  get isCompleted() {
    return this.taskForm.get('isCompleted');
  }
  get practiceAreaCode() {
    return this.taskForm.get('practiceAreaCode');
  }
  get tkRating() {
    return this.taskForm.get('tkRating');
  }

  onDateFilterChange(field: any) {
    if(this.filterDatesMatchModes[field]=='betweenDate'){
      this.filterDates[field] =null ;
    }
  }

  clearDatesFilter(field: any) {
    console.log('Clear date filter',field);
    this.filterDates[field] = null;
    this.dateFilters =this.dateFilters.filter((filter:any) => filter.field !== field);
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }
  applyDatesFilter(field: any) {
    console.log("apply", this.filterDates[field],this.filterDatesMatchModes[field]);
    let dates=this.filterDates[field]
    const index = this.dateFilters.findIndex((filter:any) => filter.field === field);
    if(index>-1){
      this.dateFilters.splice(index,1);
    }
    if(this.filterDatesMatchModes[field]=='betweenDate'){
      this.dateFilters.push({field:field, operator: "betweenDate", value:  this.filterService.convertDateToFormattedPayloadStr(dates[0]),
                            valueTo:this.filterService.convertDateToFormattedPayloadStr(dates[1])});
    }else{
      this.dateFilters.push({field:field, operator: this.filterDatesMatchModes[field], value:  this.filterService.convertDateToFormattedPayloadStr(dates)});
    }
    this.loadTasksData(this.lastTableLazyLoadEvent);
  }

  clearAllFilters(table: Table) { 
    this.dateFilters = [];
    Object.keys(this.filterDatesMatchModes).map((field:any)=>this.filterDatesMatchModes[field]='')
    Object.keys(this.filterDates).map((field:any)=>this.filterDatesMatchModes[field]='')
    table.clear();
  }

  onDateSelect(field: any,event: any) {
    if(this.filterDatesMatchModes[field]=='betweenDate' && this.filterDates[field].length==2 && this.filterDates[field][0] && this.filterDates[field][1]){
      const calendarElement=document.querySelector('.p-calendar');
      if(calendarElement){
       const overlay=document.querySelector('.p-datepicker');
       if(overlay)
        (overlay as HTMLElement).style.display='none';
      }
    }
  }

}