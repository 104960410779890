import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImportDataService {
  [x: string]: any;

  constructor(private readonly http: HttpClient) { }
  private readonly getImportDataList = environment.main_url + environment.getImportData
  private readonly uploadData = environment.main_url + environment.uploadData
//   private readonly uploadData = "http://192.168.1.32:8080/agilelegal/fileUpload/uploadOrgFile"
  

  getImportList(pageNumber:any,pageSize:any,payload:any){
    return this.http.post<any>(this.getImportDataList+"?pageNumber="+pageNumber+"&pageSize="+pageSize,payload)
  }
  uploadfile(payload:any){
    return this.http.post<any>(this.uploadData,payload)
  }

//   editUser(user:any){
//     return this.http.post<any>(this.updateUser,user)
//   }

}
