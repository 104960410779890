import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterMatchMode, LazyLoadEvent, MessageService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { helperService } from 'src/app/utils/helper';
import { OrganizationService } from './organization.service';
import { orgData } from './orgData';
import { matchModeOptionsDateList, organization } from 'src/app/utils/filterDropDownData';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

  orgData: any;
  selectedRow:any;
  cols: any[] = [];
  matchModeOptionsString?: SelectItem[];
  matchModeOptionsDate?: SelectItem[];
  matchModeOptionsNumber?: SelectItem[];
  loading: boolean = true;
  rows = 10
  viewOnly: Boolean = false;
  viewOrg: Boolean = false;
  orgDialog: Boolean = false;
  private lastTableLazyLoadEvent: any = null;
  totalRecords: number = 0;
  first: number = 1;
  title:any;
  projectMatterFilter: any;
  projectOrgFilter: any;
  ViewOrgName:any;
  matterOrgFilter: any;
  statuses:any;

  constructor( private filterService: helperService,private readonly messageService: MessageService, private orgService: OrganizationService,private router: Router) { }

  ngOnInit(): void {
   
    this.cols = [
      { field: 'name', header: 'Organisation Name' },
      { field: 'organizationType', header: 'Organisation Type' },
      { field: 'quickbookId', header: '' },
      { field: 'accountOwner', header: 'Account Owner' },
      { field: 'createdOn', header: 'Date Created', data: true, format: `MM/dd/yyyy` },
      { field: 'status', header: 'Status' },
      { field: 'activePrjCount', header: 'Active Projects' },
    ];

    this.matchModeOptionsString = [
      { label: 'Equals', value: FilterMatchMode.EQUALS },
      { label: 'Contains', value: FilterMatchMode.CONTAINS },
    ];

    this.matchModeOptionsDate = matchModeOptionsDateList;

    this.matchModeOptionsNumber = [
      { label: 'Equal', value: FilterMatchMode.EQUALS },
      { label: 'Greater than', value: FilterMatchMode.GREATER_THAN },
      { label: 'Less than', value: FilterMatchMode.LESS_THAN },
      { label: 'Between', value: FilterMatchMode.BETWEEN },
    ];

    this.statuses=organization.statuses;
  }

  loadOrgData(event: LazyLoadEvent) {
    this.loading = true;
    this.lastTableLazyLoadEvent=event;
    event.first = event.first ? event.first : 1;
    event.rows = event.rows ? event.rows : 10;
    let payload=this.filterService.generateEventPayload(event)
    this.orgService.getAllOrganizations(Math.floor(event.first / event.rows + 1), event.rows,payload).subscribe(res => {
      this.totalRecords = res.customValues.totalNumberOfRecords;
      this.loading = false;
      res.data?.forEach((element: any) => {
        element.isForceRequestedBy=element.isForceRequestedBy=='Yes'?true:false;
        element.requestedPersons=new Set(element.requestedPersons.map((val:any)=>val.name));
      });
      this.orgData = res.data;
    })
  }

  closeDialog(evnt:any) {
    this.orgDialog = false;
    this.viewOrg = false;
    this.viewOnly = false;
    this.loadOrgData(this.lastTableLazyLoadEvent);
  }

  clicked(data:any){
    this.selectedRow=data;
    this.matterOrgFilter=this.selectedRow.id;
    this.projectOrgFilter=this.selectedRow.id;
    this.viewOrg=true;
    this.ViewOrgName=this.selectedRow.name;
  }
  
  editOrg(readable: boolean, orgData: any) {
    this.orgDialog=true;
    this.selectedRow=orgData;
    if (readable) {
      this.title = "View Organization";
      this.viewOnly = true;
    }
    else {
      this.title = "Edit Organization";
      this.viewOnly=false;
    }
  }

  backToOrgGrid(){
    this.viewOrg=false;
    this.loadOrgData(this.lastTableLazyLoadEvent);
  }

}
