import {KeycloakService} from 'keycloak-angular';
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
      keycloak.init({
        config: {
          url: environment.keycloak_url,
          realm: environment.realm_name,
          clientId: environment.client_id
        },
        initOptions: {
            onLoad: 'login-required',
            checkLoginIframe:false,
            checkLoginIframeInterval:12500,
          // onLoad: 'check-sso',
          // silentCheckSsoRedirectUri:
          //   window.location.origin + '/assets/silent-check-sso.html'
        },
        bearerPrefix:'Bearer',
        loadUserProfileAtStartUp:true
      });

      // console.log(keycloak.getToken())
      // console.log(jwt_decode(keycloak.getToken()))
  }